export const FastSpringPriceFiltered = {
  "pdf-one-year-multi-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-one-year-multi-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 138.6,
        display: "$138.60",
        quantityDiscount: {
          1: {
            discountValue: 56.7,
            discountValueDisplay: "$56.70",
            unitPrice: 81.9,
            unitPriceDisplay: "$81.90",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 158.4,
        display: "158,40 $",
        quantityDiscount: {
          1: {
            discountValue: 64.8,
            discountValueDisplay: "64,80 $",
            unitPrice: 93.6,
            unitPriceDisplay: "93,60 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 151.8,
        display: "$151.80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "$62.10",
            unitPrice: 89.7,
            unitPriceDisplay: "$89.70",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 142.56,
        display: "US$142.56",
        quantityDiscount: {
          1: {
            discountValue: 58.32,
            discountValueDisplay: "US$58.32",
            unitPrice: 84.24,
            unitPriceDisplay: "US$84.24",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 158.4,
        display: "$158.40",
        quantityDiscount: {
          1: {
            discountValue: 64.8,
            discountValueDisplay: "$64.80",
            unitPrice: 93.6,
            unitPriceDisplay: "$93.60",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 157.08,
        display: "US$157,08",
        quantityDiscount: {
          1: {
            discountValue: 64.26,
            discountValueDisplay: "US$64,26",
            unitPrice: 92.82,
            unitPriceDisplay: "US$92,82",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 157.08,
        display: "US$ 157,08",
        quantityDiscount: {
          1: {
            discountValue: 64.26,
            discountValueDisplay: "US$ 64,26",
            unitPrice: 92.82,
            unitPriceDisplay: "US$ 92,82",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 141.24,
        display: "$141.24",
        quantityDiscount: {
          1: {
            discountValue: 57.78,
            discountValueDisplay: "$57.78",
            unitPrice: 83.46,
            unitPriceDisplay: "$83.46",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 155.76,
        display: "$155,76",
        quantityDiscount: {
          1: {
            discountValue: 63.72,
            discountValueDisplay: "$63,72",
            unitPrice: 92.04,
            unitPriceDisplay: "$92,04",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 138.6,
        display: "US$138.60",
        quantityDiscount: {
          1: {
            discountValue: 56.7,
            discountValueDisplay: "US$56.70",
            unitPrice: 81.9,
            unitPriceDisplay: "US$81.90",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 132,
        display: "$132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54,00",
            unitPrice: 78,
            unitPriceDisplay: "$78,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 159,
        display: "A$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "A$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "A$91.50",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 146.52,
        display: "$146.52",
        quantityDiscount: {
          1: {
            discountValue: 59.94,
            discountValueDisplay: "$59.94",
            unitPrice: 86.58,
            unitPriceDisplay: "$86.58",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 155.76,
        display: "US$ 155.76",
        quantityDiscount: {
          1: {
            discountValue: 63.72,
            discountValueDisplay: "US$ 63.72",
            unitPrice: 92.04,
            unitPriceDisplay: "US$ 92.04",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 151.8,
        display: "US$151,80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "US$62,10",
            unitPrice: 89.7,
            unitPriceDisplay: "US$89,70",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 145.2,
        display: "$145.20",
        quantityDiscount: {
          1: {
            discountValue: 59.4,
            discountValueDisplay: "$59.40",
            unitPrice: 85.8,
            unitPriceDisplay: "$85.80",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 145.2,
        display: "US$145.20",
        quantityDiscount: {
          1: {
            discountValue: 59.4,
            discountValueDisplay: "US$59.40",
            unitPrice: 85.8,
            unitPriceDisplay: "US$85.80",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 151.8,
        display: "US$151.80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "US$62.10",
            unitPrice: 89.7,
            unitPriceDisplay: "US$89.70",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
    },
  },
  "ocr-m": {
    action: "product.price.getall",
    result: "success",
    product: "ocr-m",
    pricing: {
      PR: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 4.2,
        display: "$4.20",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 4,
        display: "4,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 4,
        display: "US$ 4,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 5,
        display: "€ 5,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 7, display: "$7.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 5,
        display: "5,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 4.8,
        display: "4,80 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 4,
        display: "USD4,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 4.6,
        display: "$4.60",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 4,
        display: "US$ 4,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 4.32,
        display: "US$4.32",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 4.8,
        display: "$4.80",
        quantityDiscount: {},
      },
      SJ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 6, display: "$6.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 4,
        display: "4,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 5,
        display: "€ 5,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 4.76,
        display: "US$4,76",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 4,
        display: "US$ 4,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 4,
        display: "USD4,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 4,
        display: "4,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 4.28,
        display: "$4.28",
        quantityDiscount: {},
      },
      CX: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 4.72,
        display: "$4,72",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 4.2,
        display: "US$4.20",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 4, display: "$4,00", quantityDiscount: {} },
      US: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 4,
        display: "US$ 4,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 4,
        display: "USD4,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 4, display: "£4.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 4, display: "£4.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 4,
        display: "USD4.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 6,
        display: "A$6.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 5,
        display: "5,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 5,
        display: "5,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 4,
        display: "4,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 4.44,
        display: "$4.44",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 4, display: "£4.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 4.72,
        display: "US$ 4.72",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 4.6,
        display: "US$4,60",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 4, display: "£4.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 4.4,
        display: "$4.40",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 4.4,
        display: "US$4.40",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 4,
        display: "USD4.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 4.24,
        display: "US$4.24",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 5,
        display: "€ 5,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 5,
        display: "€ 5,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 6, display: "$6.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 4.6,
        display: "US$4.60",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 4,
        display: "USD4.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 4,
        display: "USD4.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 4, display: "$4.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 4, display: "€4.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 4,
        display: "US$4.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-multi-ocr-uk": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi-ocr-uk",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 10,
        display: "A$10.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "example-pro": {
    action: "product.price.getall",
    result: "success",
    product: "example-pro",
    pricing: {
      PR: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 72,
        display: "72,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 63,
        display: "$63.00",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 60,
        display: "60,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 60,
        display: "US$ 60,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 70,
        display: "€ 70,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 99,
        display: "$99.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 70,
        display: "€70.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 70,
        display: "70,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 72,
        display: "72,00 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 70,
        display: "€70.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 60,
        display: "USD60,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 69,
        display: "$69.00",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 60,
        display: "US$ 60,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 73,
        display: "73,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 64.8,
        display: "US$64.80",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 70,
        display: "70,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 84,
        display: "$84.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 60,
        display: "60,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 63,
        display: "€ 63,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 71.4,
        display: "US$71,40",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 71.4,
        display: "US$ 71,40",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 60,
        display: "USD60,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 60,
        display: "60,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 64.2,
        display: "$64.20",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 70,
        display: "€70.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 70.8,
        display: "$70,80",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 70,
        display: "70,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 63,
        display: "US$63.00",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 73,
        display: "73,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 60,
        display: "$60,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 70,
        display: "€70.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 60,
        display: "US$ 60,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 60,
        display: "USD60,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 72,
        display: "72,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 70,
        display: "70,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 62,
        display: "£62.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 52,
        display: "£52.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 72,
        display: "72,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 52,
        display: "£52.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 60,
        display: "USD60.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 90,
        display: "A$90.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 73,
        display: "73,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 74,
        display: "74,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 59,
        display: "59,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 66.6,
        display: "$66.60",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 72,
        display: "€72.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 52,
        display: "£52.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 70.8,
        display: "US$ 70.80",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 69,
        display: "US$69,00",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 72,
        display: "€72.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 52,
        display: "£52.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 66,
        display: "$66.00",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 66,
        display: "US$66.00",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 68,
        display: "€68.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 71,
        display: "71,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 69,
        display: "€69.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 60,
        display: "USD60.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 63.6,
        display: "US$63.60",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 71,
        display: "€ 71,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 73,
        display: "€ 73,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 90,
        display: "$90.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 69,
        display: "US$69.00",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 60,
        display: "USD60.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 60,
        display: "USD60.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 60,
        display: "$60.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 72,
        display: "72,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 59,
        display: "€59.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 60,
        display: "US$60.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-multi-australia": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi-australia",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 8,
        display: "A$8.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-multi-ocr-australia": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi-ocr-australia",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 10,
        display: "A$10.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-perpetual": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-perpetual",
    pricing: {
      PR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 201.6,
        display: "$201.60",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 230.4,
        display: "230,40 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 220.8,
        display: "$220.80",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 207.36,
        display: "US$207.36",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 230.4,
        display: "$230.40",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 228.48,
        display: "US$228,48",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 228.48,
        display: "US$ 228,48",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 205.44,
        display: "$205.44",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 226.56,
        display: "$226,56",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 201.6,
        display: "US$201.60",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 192,
        display: "$192,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 249,
        display: "A$249.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 213.12,
        display: "$213.12",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 226.56,
        display: "US$ 226.56",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 220.8,
        display: "US$220,80",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 211.2,
        display: "$211.20",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 211.2,
        display: "US$211.20",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 203.52,
        display: "US$203.52",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 220.8,
        display: "US$220.80",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-ocr-australia": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-ocr-australia",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 10,
        display: "A$10.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly",
    pricing: {
      PR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 15.75,
        display: "$15.75",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 18,
        display: "18,00 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 17.25,
        display: "$17.25",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 16.2,
        display: "US$16.20",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 18,
        display: "$18.00",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 17.85,
        display: "US$17,85",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 17.85,
        display: "US$ 17,85",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 16.05,
        display: "$16.05",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 17.7,
        display: "$17,70",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 15.75,
        display: "US$15.75",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 15,
        display: "$15,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 17,
        display: "A$17.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 16.65,
        display: "$16.65",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 17.7,
        display: "US$ 17.70",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 17.25,
        display: "US$17,25",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 16.5,
        display: "$16.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 16.5,
        display: "US$16.50",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 17.25,
        display: "US$17.25",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-two-year-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-two-year-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 315,
        display: "315,00 €",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "157,01 €",
            unitPrice: 157.99,
            unitPriceDisplay: "157,99 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 277.2,
        display: "$277.20",
        quantityDiscount: {
          1: {
            discountValue: 138.6,
            discountValueDisplay: "$138.60",
            unitPrice: 138.6,
            unitPriceDisplay: "$138.60",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 307,
        display: "€ 307,00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€ 153,18",
            unitPrice: 153.82,
            unitPriceDisplay: "€ 153,82",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 435,
        display: "$435.00",
        quantityDiscount: {
          1: {
            discountValue: 217.43,
            discountValueDisplay: "$217.43",
            unitPrice: 217.57,
            unitPriceDisplay: "$217.57",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 304,
        display: "€304.00",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "€151.90",
            unitPrice: 152.1,
            unitPriceDisplay: "€152.10",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 307,
        display: "307,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 EUR",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 316.8,
        display: "316,80 $",
        quantityDiscount: {
          1: {
            discountValue: 158.4,
            discountValueDisplay: "158,40 $",
            unitPrice: 158.4,
            unitPriceDisplay: "158,40 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 307,
        display: "€307.00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€153.18",
            unitPrice: 153.82,
            unitPriceDisplay: "€153.82",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 303.6,
        display: "$303.60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "$151.80",
            unitPrice: 151.8,
            unitPriceDisplay: "$151.80",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 320,
        display: "320,00 €",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 €",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 285.12,
        display: "US$285.12",
        quantityDiscount: {
          1: {
            discountValue: 142.56,
            discountValueDisplay: "US$142.56",
            unitPrice: 142.56,
            unitPriceDisplay: "US$142.56",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 312,
        display: "312,00 €",
        quantityDiscount: {
          1: {
            discountValue: 155.73,
            discountValueDisplay: "155,73 €",
            unitPrice: 156.27,
            unitPriceDisplay: "156,27 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 316.8,
        display: "$316.80",
        quantityDiscount: {
          1: {
            discountValue: 158.4,
            discountValueDisplay: "$158.40",
            unitPrice: 158.4,
            unitPriceDisplay: "$158.40",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 307,
        display: "307,00 €",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 €",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 366,
        display: "$366.00",
        quantityDiscount: {
          1: {
            discountValue: 182.93,
            discountValueDisplay: "$182.93",
            unitPrice: 183.07,
            unitPriceDisplay: "$183.07",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 275,
        display: "€ 275,00",
        quantityDiscount: {
          1: {
            discountValue: 137.48,
            discountValueDisplay: "€ 137,48",
            unitPrice: 137.52,
            unitPriceDisplay: "€ 137,52",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 314.16,
        display: "US$314,16",
        quantityDiscount: {
          1: {
            discountValue: 157.08,
            discountValueDisplay: "US$157,08",
            unitPrice: 157.08,
            unitPriceDisplay: "US$157,08",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 314.16,
        display: "US$ 314,16",
        quantityDiscount: {
          1: {
            discountValue: 157.08,
            discountValueDisplay: "US$ 157,08",
            unitPrice: 157.08,
            unitPriceDisplay: "US$ 157,08",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 282.48,
        display: "$282.48",
        quantityDiscount: {
          1: {
            discountValue: 141.24,
            discountValueDisplay: "$141.24",
            unitPrice: 141.24,
            unitPriceDisplay: "$141.24",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 304,
        display: "€304.00",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "€151.90",
            unitPrice: 152.1,
            unitPriceDisplay: "€152.10",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 311.52,
        display: "$311,52",
        quantityDiscount: {
          1: {
            discountValue: 155.76,
            discountValueDisplay: "$155,76",
            unitPrice: 155.76,
            unitPriceDisplay: "$155,76",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 304,
        display: "304,00 €",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "151,90 €",
            unitPrice: 152.1,
            unitPriceDisplay: "152,10 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 277.2,
        display: "US$277.20",
        quantityDiscount: {
          1: {
            discountValue: 138.6,
            discountValueDisplay: "US$138.60",
            unitPrice: 138.6,
            unitPriceDisplay: "US$138.60",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 320,
        display: "320,00 €",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 €",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 264,
        display: "$264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132,00",
            unitPrice: 132,
            unitPriceDisplay: "$132,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 307,
        display: "€307.00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€153.18",
            unitPrice: 153.82,
            unitPriceDisplay: "€153.82",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 317,
        display: "317,00 €",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "158,29 €",
            unitPrice: 158.71,
            unitPriceDisplay: "158,71 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 307,
        display: "307,00 €",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 €",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 272,
        display: "£272.00",
        quantityDiscount: {
          1: {
            discountValue: 135.77,
            discountValueDisplay: "£135.77",
            unitPrice: 136.23,
            unitPriceDisplay: "£136.23",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 317,
        display: "317,00 €",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "158,29 €",
            unitPrice: 158.71,
            unitPriceDisplay: "158,71 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 396,
        display: "A$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "A$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "A$198.34",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 320,
        display: "320,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 EUR",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 325,
        display: "325,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 162.12,
            discountValueDisplay: "162,12 EUR",
            unitPrice: 162.88,
            unitPriceDisplay: "162,88 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 256,
        display: "256,00 €",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "127,65 €",
            unitPrice: 128.35,
            unitPriceDisplay: "128,35 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 293.04,
        display: "$293.04",
        quantityDiscount: {
          1: {
            discountValue: 146.52,
            discountValueDisplay: "$146.52",
            unitPrice: 146.52,
            unitPriceDisplay: "$146.52",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 315,
        display: "€315.00",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "€157.01",
            unitPrice: 157.99,
            unitPriceDisplay: "€157.99",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 311.52,
        display: "US$ 311.52",
        quantityDiscount: {
          1: {
            discountValue: 155.76,
            discountValueDisplay: "US$ 155.76",
            unitPrice: 155.76,
            unitPriceDisplay: "US$ 155.76",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 303.6,
        display: "US$303,60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "US$151,80",
            unitPrice: 151.8,
            unitPriceDisplay: "US$151,80",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 317,
        display: "€317.00",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "€158.29",
            unitPrice: 158.71,
            unitPriceDisplay: "€158.71",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 312,
        display: "312,00 €",
        quantityDiscount: {
          1: {
            discountValue: 155.73,
            discountValueDisplay: "155,73 €",
            unitPrice: 156.27,
            unitPriceDisplay: "156,27 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 290.4,
        display: "$290.40",
        quantityDiscount: {
          1: {
            discountValue: 145.2,
            discountValueDisplay: "$145.20",
            unitPrice: 145.2,
            unitPriceDisplay: "$145.20",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 290.4,
        display: "US$290.40",
        quantityDiscount: {
          1: {
            discountValue: 145.2,
            discountValueDisplay: "US$145.20",
            unitPrice: 145.2,
            unitPriceDisplay: "US$145.20",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 299,
        display: "€299.00",
        quantityDiscount: {
          1: {
            discountValue: 149.35,
            discountValueDisplay: "€149.35",
            unitPrice: 149.65,
            unitPriceDisplay: "€149.65",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 302,
        display: "€302.00",
        quantityDiscount: {
          1: {
            discountValue: 150.63,
            discountValueDisplay: "€150.63",
            unitPrice: 151.37,
            unitPriceDisplay: "€151.37",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 309,
        display: "€ 309,00",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "€ 154,46",
            unitPrice: 154.54,
            unitPriceDisplay: "€ 154,54",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 320,
        display: "€ 320,00",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "€ 159,56",
            unitPrice: 160.44,
            unitPriceDisplay: "€ 160,44",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 303.6,
        display: "US$303.60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "US$151.80",
            unitPrice: 151.8,
            unitPriceDisplay: "US$151.80",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 315,
        display: "315,00 €",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "157,01 €",
            unitPrice: 157.99,
            unitPriceDisplay: "157,99 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
    },
  },
  "pdf-one-year": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-one-year",
    pricing: {
      PR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 100.8,
        display: "$100.80",
        quantityDiscount: {
          1: {
            discountValue: 37.8,
            discountValueDisplay: "$37.80",
            unitPrice: 63,
            unitPriceDisplay: "$63.00",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 115.2,
        display: "115,20 $",
        quantityDiscount: {
          1: {
            discountValue: 43.2,
            discountValueDisplay: "43,20 $",
            unitPrice: 72,
            unitPriceDisplay: "72,00 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 110.4,
        display: "$110.40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "$41.40",
            unitPrice: 69,
            unitPriceDisplay: "$69.00",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 103.68,
        display: "US$103.68",
        quantityDiscount: {
          1: {
            discountValue: 38.88,
            discountValueDisplay: "US$38.88",
            unitPrice: 64.8,
            unitPriceDisplay: "US$64.80",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 115.2,
        display: "$115.20",
        quantityDiscount: {
          1: {
            discountValue: 43.2,
            discountValueDisplay: "$43.20",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 114.24,
        display: "US$114,24",
        quantityDiscount: {
          1: {
            discountValue: 42.84,
            discountValueDisplay: "US$42,84",
            unitPrice: 71.4,
            unitPriceDisplay: "US$71,40",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 114.24,
        display: "US$ 114,24",
        quantityDiscount: {
          1: {
            discountValue: 42.84,
            discountValueDisplay: "US$ 42,84",
            unitPrice: 71.4,
            unitPriceDisplay: "US$ 71,40",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 102.72,
        display: "$102.72",
        quantityDiscount: {
          1: {
            discountValue: 38.52,
            discountValueDisplay: "$38.52",
            unitPrice: 64.2,
            unitPriceDisplay: "$64.20",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 113.28,
        display: "$113,28",
        quantityDiscount: {
          1: {
            discountValue: 42.48,
            discountValueDisplay: "$42,48",
            unitPrice: 70.8,
            unitPriceDisplay: "$70,80",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 100.8,
        display: "US$100.80",
        quantityDiscount: {
          1: {
            discountValue: 37.8,
            discountValueDisplay: "US$37.80",
            unitPrice: 63,
            unitPriceDisplay: "US$63.00",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 96,
        display: "$96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36,00",
            unitPrice: 60,
            unitPriceDisplay: "$60,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 120,
        display: "A$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "A$48.00",
            unitPrice: 72,
            unitPriceDisplay: "A$72.00",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 106.56,
        display: "$106.56",
        quantityDiscount: {
          1: {
            discountValue: 39.96,
            discountValueDisplay: "$39.96",
            unitPrice: 66.6,
            unitPriceDisplay: "$66.60",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 113.28,
        display: "US$ 113.28",
        quantityDiscount: {
          1: {
            discountValue: 42.48,
            discountValueDisplay: "US$ 42.48",
            unitPrice: 70.8,
            unitPriceDisplay: "US$ 70.80",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 110.4,
        display: "US$110,40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "US$41,40",
            unitPrice: 69,
            unitPriceDisplay: "US$69,00",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 105.6,
        display: "$105.60",
        quantityDiscount: {
          1: {
            discountValue: 39.6,
            discountValueDisplay: "$39.60",
            unitPrice: 66,
            unitPriceDisplay: "$66.00",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 105.6,
        display: "US$105.60",
        quantityDiscount: {
          1: {
            discountValue: 39.6,
            discountValueDisplay: "US$39.60",
            unitPrice: 66,
            unitPriceDisplay: "US$66.00",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 110.4,
        display: "US$110.40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "US$41.40",
            unitPrice: 69,
            unitPriceDisplay: "US$69.00",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
    },
  },
  "pdf-monthly-australia": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-australia",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 8,
        display: "A$8.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-one-year-multi": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-one-year-multi",
    pricing: {
      PR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 100.8,
        display: "$100.80",
        quantityDiscount: {
          1: {
            discountValue: 37.8,
            discountValueDisplay: "$37.80",
            unitPrice: 63,
            unitPriceDisplay: "$63.00",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 115.2,
        display: "115,20 $",
        quantityDiscount: {
          1: {
            discountValue: 43.2,
            discountValueDisplay: "43,20 $",
            unitPrice: 72,
            unitPriceDisplay: "72,00 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 110.4,
        display: "$110.40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "$41.40",
            unitPrice: 69,
            unitPriceDisplay: "$69.00",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 103.68,
        display: "US$103.68",
        quantityDiscount: {
          1: {
            discountValue: 38.88,
            discountValueDisplay: "US$38.88",
            unitPrice: 64.8,
            unitPriceDisplay: "US$64.80",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 115.2,
        display: "$115.20",
        quantityDiscount: {
          1: {
            discountValue: 43.2,
            discountValueDisplay: "$43.20",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 114.24,
        display: "US$114,24",
        quantityDiscount: {
          1: {
            discountValue: 42.84,
            discountValueDisplay: "US$42,84",
            unitPrice: 71.4,
            unitPriceDisplay: "US$71,40",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 114.24,
        display: "US$ 114,24",
        quantityDiscount: {
          1: {
            discountValue: 42.84,
            discountValueDisplay: "US$ 42,84",
            unitPrice: 71.4,
            unitPriceDisplay: "US$ 71,40",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 96,
        display: "96,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 US$",
            unitPrice: 60,
            unitPriceDisplay: "60,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 102.72,
        display: "$102.72",
        quantityDiscount: {
          1: {
            discountValue: 38.52,
            discountValueDisplay: "$38.52",
            unitPrice: 64.2,
            unitPriceDisplay: "$64.20",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 113.28,
        display: "$113,28",
        quantityDiscount: {
          1: {
            discountValue: 42.48,
            discountValueDisplay: "$42,48",
            unitPrice: 70.8,
            unitPriceDisplay: "$70,80",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 100.8,
        display: "US$100.80",
        quantityDiscount: {
          1: {
            discountValue: 37.8,
            discountValueDisplay: "US$37.80",
            unitPrice: 63,
            unitPriceDisplay: "US$63.00",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 96,
        display: "$96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36,00",
            unitPrice: 60,
            unitPriceDisplay: "$60,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 96,
        display: "US$ 96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$ 36,00",
            unitPrice: 60,
            unitPriceDisplay: "US$ 60,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 96,
        display: "USD96,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36,00",
            unitPrice: 60,
            unitPriceDisplay: "USD60,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 120,
        display: "A$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "A$48.00",
            unitPrice: 72,
            unitPriceDisplay: "A$72.00",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 108,
        display: "108,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 72,
            unitPriceDisplay: "72,00 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 106.56,
        display: "$106.56",
        quantityDiscount: {
          1: {
            discountValue: 39.96,
            discountValueDisplay: "$39.96",
            unitPrice: 66.6,
            unitPriceDisplay: "$66.60",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 113.28,
        display: "US$ 113.28",
        quantityDiscount: {
          1: {
            discountValue: 42.48,
            discountValueDisplay: "US$ 42.48",
            unitPrice: 70.8,
            unitPriceDisplay: "US$ 70.80",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 110.4,
        display: "US$110,40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "US$41,40",
            unitPrice: 69,
            unitPriceDisplay: "US$69,00",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 96,
        display: "£96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "£36.00",
            unitPrice: 60,
            unitPriceDisplay: "£60.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 105.6,
        display: "$105.60",
        quantityDiscount: {
          1: {
            discountValue: 39.6,
            discountValueDisplay: "$39.60",
            unitPrice: 66,
            unitPriceDisplay: "$66.00",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 105.6,
        display: "US$105.60",
        quantityDiscount: {
          1: {
            discountValue: 39.6,
            discountValueDisplay: "US$39.60",
            unitPrice: 66,
            unitPriceDisplay: "US$66.00",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 108,
        display: "€ 108,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 72,
            unitPriceDisplay: "€ 72,00",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 120,
        display: "$120.00",
        quantityDiscount: {
          1: {
            discountValue: 48,
            discountValueDisplay: "$48.00",
            unitPrice: 72,
            unitPriceDisplay: "$72.00",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 110.4,
        display: "US$110.40",
        quantityDiscount: {
          1: {
            discountValue: 41.4,
            discountValueDisplay: "US$41.40",
            unitPrice: 69,
            unitPriceDisplay: "US$69.00",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 96,
        display: "USD96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "USD36.00",
            unitPrice: 60,
            unitPriceDisplay: "USD60.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 96,
        display: "$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 60,
            unitPriceDisplay: "$60.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 108,
        display: "108,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 72,
            unitPriceDisplay: "72,00 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 108,
        display: "€108.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 72,
            unitPriceDisplay: "€72.00",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 96,
        display: "US$96.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "US$36.00",
            unitPrice: 60,
            unitPriceDisplay: "US$60.00",
          },
        },
      },
    },
  },
  "pdf-monthly-ocr-uk": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-ocr-uk",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 10,
        display: "A$10.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  example: {
    action: "product.price.getall",
    result: "success",
    product: "example",
    pricing: {
      PR: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 59.95,
        display: "59,95 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 52.45,
        display: "$52.45",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 49.95,
        display: "49,95 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 49.95,
        display: "US$ 49,95",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 58.95,
        display: "€ 58,95",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 82.95,
        display: "$82.95",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 57.95,
        display: "€57.95",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 59.94,
        display: "59,94 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 58.95,
        display: "€58.95",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 49.95,
        display: "USD49,95",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 57.44,
        display: "$57.44",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 49.95,
        display: "US$ 49,95",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 60.95,
        display: "60,95 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 53.95,
        display: "US$53.95",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 59.94,
        display: "$59.94",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 69.95,
        display: "$69.95",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 49.95,
        display: "49,95 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 52.95,
        display: "€ 52,95",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 59.44,
        display: "US$59,44",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 59.44,
        display: "US$ 59,44",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 49.95,
        display: "USD49,95",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 49.95,
        display: "49,95 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 53.45,
        display: "$53.45",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 57.95,
        display: "€57.95",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 58.94,
        display: "$58,94",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 57.95,
        display: "57,95 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 52.45,
        display: "US$52.45",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 60.95,
        display: "60,95 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 49.95,
        display: "$49,95",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 58.95,
        display: "€58.95",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 49.95,
        display: "US$ 49,95",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 49.95,
        display: "USD49,95",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 59.95,
        display: "59,95 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 51.95,
        display: "£51.95",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 42.95,
        display: "£42.95",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 59.95,
        display: "59,95 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 42.95,
        display: "£42.95",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 49.95,
        display: "USD49.95",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 74.95,
        display: "A$74.95",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 60.95,
        display: "60,95 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 61.95,
        display: "61,95 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 48.95,
        display: "48,95 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 55.44,
        display: "$55.44",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 59.95,
        display: "€59.95",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 42.95,
        display: "£42.95",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 58.94,
        display: "US$ 58.94",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 57.44,
        display: "US$57,44",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 59.95,
        display: "€59.95",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 42.95,
        display: "£42.95",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 54.95,
        display: "$54.95",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 54.95,
        display: "US$54.95",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 56.95,
        display: "€56.95",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 58.95,
        display: "58,95 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 57.95,
        display: "€57.95",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 49.95,
        display: "USD49.95",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 52.95,
        display: "US$52.95",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 58.95,
        display: "€ 58,95",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 60.95,
        display: "€ 60,95",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 74.95,
        display: "$74.95",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 57.44,
        display: "US$57.44",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 49.95,
        display: "USD49.95",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 49.95,
        display: "USD49.95",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 49.95,
        display: "$49.95",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 59.95,
        display: "59,95 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 48.95,
        display: "€48.95",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 49.95,
        display: "US$49.95",
        quantityDiscount: {},
      },
    },
  },
  "pdf-perpetual-multi": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-perpetual-multi",
    pricing: {
      PR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 201.6,
        display: "$201.60",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 230.4,
        display: "230,40 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 220.8,
        display: "$220.80",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 207.36,
        display: "US$207.36",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 230.4,
        display: "$230.40",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 228.48,
        display: "US$228,48",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 228.48,
        display: "US$ 228,48",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 205.44,
        display: "$205.44",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 226.56,
        display: "$226,56",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 201.6,
        display: "US$201.60",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 192,
        display: "$192,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 249,
        display: "A$249.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 199,
        display: "199,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 213.12,
        display: "$213.12",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 226.56,
        display: "US$ 226.56",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 220.8,
        display: "US$220,80",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 189,
        display: "£189.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 211.2,
        display: "$211.20",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 211.2,
        display: "US$211.20",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 203.52,
        display: "US$203.52",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 199,
        display: "€ 199,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 249,
        display: "$249.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 220.8,
        display: "US$220.80",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 199,
        display: "199,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 199,
        display: "€199.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {},
      },
    },
  },
  "ocr-one": {
    action: "product.price.getall",
    result: "success",
    product: "ocr-one",
    pricing: {
      PR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.27,
            discountValueDisplay: "14,27 €",
            unitPrice: 43.73,
            unitPriceDisplay: "43,73 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 50.4,
        display: "$50.40",
        quantityDiscount: {
          1: {
            discountValue: 12.6,
            discountValueDisplay: "$12.60",
            unitPrice: 37.8,
            unitPriceDisplay: "$37.80",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "12,00 US$",
            unitPrice: 36,
            unitPriceDisplay: "36,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$ 12,00",
            unitPrice: 36,
            unitPriceDisplay: "US$ 36,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 56,
        display: "€ 56,00",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "€ 13,92",
            unitPrice: 42.08,
            unitPriceDisplay: "€ 42,08",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 80,
        display: "$80.00",
        quantityDiscount: {
          1: {
            discountValue: 19.77,
            discountValueDisplay: "$19.77",
            unitPrice: 60.23,
            unitPriceDisplay: "$60.23",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "€13.80",
            unitPrice: 42.2,
            unitPriceDisplay: "€42.20",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 56,
        display: "56,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "13,92 EUR",
            unitPrice: 42.08,
            unitPriceDisplay: "42,08 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "14,04 €",
            unitPrice: 42.96,
            unitPriceDisplay: "42,96 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 57.6,
        display: "57,60 $",
        quantityDiscount: {
          1: {
            discountValue: 14.4,
            discountValueDisplay: "14,40 $",
            unitPrice: 43.2,
            unitPriceDisplay: "43,20 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "€13.92",
            unitPrice: 42.08,
            unitPriceDisplay: "€42.08",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12,00",
            unitPrice: 36,
            unitPriceDisplay: "USD36,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 55.2,
        display: "$55.20",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "$13.80",
            unitPrice: 41.4,
            unitPriceDisplay: "$41.40",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$ 12,00",
            unitPrice: 36,
            unitPriceDisplay: "US$ 36,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 59,
        display: "59,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.5,
            discountValueDisplay: "14,50 €",
            unitPrice: 44.5,
            unitPriceDisplay: "44,50 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 51.84,
        display: "US$51.84",
        quantityDiscount: {
          1: {
            discountValue: 12.96,
            discountValueDisplay: "US$12.96",
            unitPrice: 38.88,
            unitPriceDisplay: "US$38.88",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.15,
            discountValueDisplay: "14,15 €",
            unitPrice: 42.85,
            unitPriceDisplay: "42,85 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 57.6,
        display: "$57.60",
        quantityDiscount: {
          1: {
            discountValue: 14.4,
            discountValueDisplay: "$14.40",
            unitPrice: 43.2,
            unitPriceDisplay: "$43.20",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "13,92 €",
            unitPrice: 42.08,
            unitPriceDisplay: "42,08 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 67,
        display: "$67.00",
        quantityDiscount: {
          1: {
            discountValue: 16.63,
            discountValueDisplay: "$16.63",
            unitPrice: 50.37,
            unitPriceDisplay: "$50.37",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "12,00 US$",
            unitPrice: 36,
            unitPriceDisplay: "36,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 50,
        display: "€ 50,00",
        quantityDiscount: {
          1: {
            discountValue: 12.49,
            discountValueDisplay: "€ 12,49",
            unitPrice: 37.51,
            unitPriceDisplay: "€ 37,51",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 57.12,
        display: "US$57,12",
        quantityDiscount: {
          1: {
            discountValue: 14.28,
            discountValueDisplay: "US$14,28",
            unitPrice: 42.84,
            unitPriceDisplay: "US$42,84",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 57.12,
        display: "US$ 57,12",
        quantityDiscount: {
          1: {
            discountValue: 14.28,
            discountValueDisplay: "US$ 14,28",
            unitPrice: 42.84,
            unitPriceDisplay: "US$ 42,84",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12,00",
            unitPrice: 36,
            unitPriceDisplay: "USD36,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "12,00 US$",
            unitPrice: 36,
            unitPriceDisplay: "36,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 51.36,
        display: "$51.36",
        quantityDiscount: {
          1: {
            discountValue: 12.84,
            discountValueDisplay: "$12.84",
            unitPrice: 38.52,
            unitPriceDisplay: "$38.52",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "€13.80",
            unitPrice: 42.2,
            unitPriceDisplay: "€42.20",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "14,04 €",
            unitPrice: 42.96,
            unitPriceDisplay: "42,96 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 56.64,
        display: "$56,64",
        quantityDiscount: {
          1: {
            discountValue: 14.16,
            discountValueDisplay: "$14,16",
            unitPrice: 42.48,
            unitPriceDisplay: "$42,48",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "13,80 €",
            unitPrice: 42.2,
            unitPriceDisplay: "42,20 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 50.4,
        display: "US$50.40",
        quantityDiscount: {
          1: {
            discountValue: 12.6,
            discountValueDisplay: "US$12.60",
            unitPrice: 37.8,
            unitPriceDisplay: "US$37.80",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 59,
        display: "59,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.5,
            discountValueDisplay: "14,50 €",
            unitPrice: 44.5,
            unitPriceDisplay: "44,50 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 48,
        display: "$48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12,00",
            unitPrice: 36,
            unitPriceDisplay: "$36,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "€13.92",
            unitPrice: 42.08,
            unitPriceDisplay: "€42.08",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$ 12,00",
            unitPrice: 36,
            unitPriceDisplay: "US$ 36,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "14,04 €",
            unitPrice: 42.96,
            unitPriceDisplay: "42,96 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12,00",
            unitPrice: 36,
            unitPriceDisplay: "USD36,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.38,
            discountValueDisplay: "14,38 €",
            unitPrice: 43.62,
            unitPriceDisplay: "43,62 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 13.92,
            discountValueDisplay: "13,92 €",
            unitPrice: 42.08,
            unitPriceDisplay: "42,08 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 50,
        display: "£50.00",
        quantityDiscount: {
          1: {
            discountValue: 12.35,
            discountValueDisplay: "£12.35",
            unitPrice: 37.65,
            unitPriceDisplay: "£37.65",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 10.29,
            discountValueDisplay: "£10.29",
            unitPrice: 31.71,
            unitPriceDisplay: "£31.71",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.38,
            discountValueDisplay: "14,38 €",
            unitPrice: 43.62,
            unitPriceDisplay: "43,62 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 10.29,
            discountValueDisplay: "£10.29",
            unitPrice: 31.71,
            unitPriceDisplay: "£31.71",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12.00",
            unitPrice: 36,
            unitPriceDisplay: "USD36.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 72,
        display: "A$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "A$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "A$54.03",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 59,
        display: "59,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 14.5,
            discountValueDisplay: "14,50 EUR",
            unitPrice: 44.5,
            unitPriceDisplay: "44,50 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 59,
        display: "59,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 14.73,
            discountValueDisplay: "14,73 EUR",
            unitPrice: 44.27,
            unitPriceDisplay: "44,27 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 47,
        display: "47,00 €",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "11,60 €",
            unitPrice: 35.4,
            unitPriceDisplay: "35,40 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 53.28,
        display: "$53.28",
        quantityDiscount: {
          1: {
            discountValue: 13.32,
            discountValueDisplay: "$13.32",
            unitPrice: 39.96,
            unitPriceDisplay: "$39.96",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 58,
        display: "€58.00",
        quantityDiscount: {
          1: {
            discountValue: 14.27,
            discountValueDisplay: "€14.27",
            unitPrice: 43.73,
            unitPriceDisplay: "€43.73",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 10.29,
            discountValueDisplay: "£10.29",
            unitPrice: 31.71,
            unitPriceDisplay: "£31.71",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 56.64,
        display: "US$ 56.64",
        quantityDiscount: {
          1: {
            discountValue: 14.16,
            discountValueDisplay: "US$ 14.16",
            unitPrice: 42.48,
            unitPriceDisplay: "US$ 42.48",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 55.2,
        display: "US$55,20",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "US$13,80",
            unitPrice: 41.4,
            unitPriceDisplay: "US$41,40",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 58,
        display: "€58.00",
        quantityDiscount: {
          1: {
            discountValue: 14.38,
            discountValueDisplay: "€14.38",
            unitPrice: 43.62,
            unitPriceDisplay: "€43.62",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.15,
            discountValueDisplay: "14,15 €",
            unitPrice: 42.85,
            unitPriceDisplay: "42,85 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 10.29,
            discountValueDisplay: "£10.29",
            unitPrice: 31.71,
            unitPriceDisplay: "£31.71",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 52.8,
        display: "$52.80",
        quantityDiscount: {
          1: {
            discountValue: 13.2,
            discountValueDisplay: "$13.20",
            unitPrice: 39.6,
            unitPriceDisplay: "$39.60",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 52.8,
        display: "US$52.80",
        quantityDiscount: {
          1: {
            discountValue: 13.2,
            discountValueDisplay: "US$13.20",
            unitPrice: 39.6,
            unitPriceDisplay: "US$39.60",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "14,04 €",
            unitPrice: 42.96,
            unitPriceDisplay: "42,96 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 55,
        display: "€55.00",
        quantityDiscount: {
          1: {
            discountValue: 13.57,
            discountValueDisplay: "€13.57",
            unitPrice: 41.43,
            unitPriceDisplay: "€41.43",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "14,04 €",
            unitPrice: 42.96,
            unitPriceDisplay: "42,96 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 55,
        display: "€55.00",
        quantityDiscount: {
          1: {
            discountValue: 13.69,
            discountValueDisplay: "€13.69",
            unitPrice: 41.31,
            unitPriceDisplay: "€41.31",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12.00",
            unitPrice: 36,
            unitPriceDisplay: "USD36.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 57,
        display: "€ 57,00",
        quantityDiscount: {
          1: {
            discountValue: 14.04,
            discountValueDisplay: "€ 14,04",
            unitPrice: 42.96,
            unitPriceDisplay: "€ 42,96",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 59,
        display: "€ 59,00",
        quantityDiscount: {
          1: {
            discountValue: 14.5,
            discountValueDisplay: "€ 14,50",
            unitPrice: 44.5,
            unitPriceDisplay: "€ 44,50",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 17.97,
            discountValueDisplay: "$17.97",
            unitPrice: 54.03,
            unitPriceDisplay: "$54.03",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 55.2,
        display: "US$55.20",
        quantityDiscount: {
          1: {
            discountValue: 13.8,
            discountValueDisplay: "US$13.80",
            unitPrice: 41.4,
            unitPriceDisplay: "US$41.40",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12.00",
            unitPrice: 36,
            unitPriceDisplay: "USD36.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "USD12.00",
            unitPrice: 36,
            unitPriceDisplay: "USD36.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "$12.00",
            unitPrice: 36,
            unitPriceDisplay: "$36.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 14.27,
            discountValueDisplay: "14,27 €",
            unitPrice: 43.73,
            unitPriceDisplay: "43,73 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 11.6,
            discountValueDisplay: "€11.60",
            unitPrice: 35.4,
            unitPriceDisplay: "€35.40",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 12,
            discountValueDisplay: "US$12.00",
            unitPrice: 36,
            unitPriceDisplay: "US$36.00",
          },
        },
      },
    },
  },
  "pdf-monthly-multi": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi",
    pricing: {
      PR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 15.75,
        display: "$15.75",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 18,
        display: "18,00 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 17.25,
        display: "$17.25",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 16.2,
        display: "US$16.20",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 18,
        display: "$18.00",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 17.85,
        display: "US$17,85",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 17.85,
        display: "US$ 17,85",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 15,
        display: "15,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 16.05,
        display: "$16.05",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 17.7,
        display: "$17,70",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 15.75,
        display: "US$15.75",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 15,
        display: "$15,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 15,
        display: "US$ 15,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 15,
        display: "USD15,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 17,
        display: "A$17.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 15,
        display: "15,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 16.65,
        display: "$16.65",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 17.7,
        display: "US$ 17.70",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 17.25,
        display: "US$17,25",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 15,
        display: "£15.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 16.5,
        display: "$16.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 16.5,
        display: "US$16.50",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 15,
        display: "€ 15,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 17,
        display: "$17.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 17.25,
        display: "US$17.25",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 15,
        display: "USD15.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 15,
        display: "$15.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 15,
        display: "15,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 15,
        display: "€15.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 15,
        display: "US$15.00",
        quantityDiscount: {},
      },
    },
  },
  "edit-text-images": {
    action: "product.price.getall",
    result: "success",
    product: "edit-text-images",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-multi-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 21,
        display: "$21.00",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 24,
        display: "24,00 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 21.6,
        display: "US$21.60",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 24,
        display: "$24.00",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 23.8,
        display: "US$23,80",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 23.8,
        display: "US$ 23,80",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 21.4,
        display: "$21.40",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 23.6,
        display: "$23,60",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 21,
        display: "US$21.00",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 20,
        display: "$20,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 23,
        display: "A$23.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 22.2,
        display: "$22.20",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 23.6,
        display: "US$ 23.60",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 23,
        display: "US$23,00",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 22,
        display: "$22.00",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 22,
        display: "US$22.00",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 23,
        display: "US$23.00",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
    },
  },
  "ocr-two": {
    action: "product.price.getall",
    result: "success",
    product: "ocr-two",
    pricing: {
      PR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.03,
            discountValueDisplay: "19,03 €",
            unitPrice: 38.97,
            unitPriceDisplay: "38,97 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 50.4,
        display: "$50.40",
        quantityDiscount: {
          1: {
            discountValue: 16.8,
            discountValueDisplay: "$16.80",
            unitPrice: 33.6,
            unitPriceDisplay: "$33.60",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "16,00 US$",
            unitPrice: 32,
            unitPriceDisplay: "32,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$ 16,00",
            unitPrice: 32,
            unitPriceDisplay: "US$ 32,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 56,
        display: "€ 56,00",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "€ 18,56",
            unitPrice: 37.44,
            unitPriceDisplay: "€ 37,44",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 80,
        display: "$80.00",
        quantityDiscount: {
          1: {
            discountValue: 26.36,
            discountValueDisplay: "$26.36",
            unitPrice: 53.64,
            unitPriceDisplay: "$53.64",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 18.41,
            discountValueDisplay: "€18.41",
            unitPrice: 37.59,
            unitPriceDisplay: "€37.59",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 56,
        display: "56,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "18,56 EUR",
            unitPrice: 37.44,
            unitPriceDisplay: "37,44 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "18,72 €",
            unitPrice: 38.28,
            unitPriceDisplay: "38,28 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 57.6,
        display: "57,60 $",
        quantityDiscount: {
          1: {
            discountValue: 19.2,
            discountValueDisplay: "19,20 $",
            unitPrice: 38.4,
            unitPriceDisplay: "38,40 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "€18.56",
            unitPrice: 37.44,
            unitPriceDisplay: "€37.44",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16,00",
            unitPrice: 32,
            unitPriceDisplay: "USD32,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 55.2,
        display: "$55.20",
        quantityDiscount: {
          1: {
            discountValue: 18.4,
            discountValueDisplay: "$18.40",
            unitPrice: 36.8,
            unitPriceDisplay: "$36.80",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$ 16,00",
            unitPrice: 32,
            unitPriceDisplay: "US$ 32,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 59,
        display: "59,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.34,
            discountValueDisplay: "19,34 €",
            unitPrice: 39.66,
            unitPriceDisplay: "39,66 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 51.84,
        display: "US$51.84",
        quantityDiscount: {
          1: {
            discountValue: 17.28,
            discountValueDisplay: "US$17.28",
            unitPrice: 34.56,
            unitPriceDisplay: "US$34.56",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.87,
            discountValueDisplay: "18,87 €",
            unitPrice: 38.13,
            unitPriceDisplay: "38,13 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 57.6,
        display: "$57.60",
        quantityDiscount: {
          1: {
            discountValue: 19.2,
            discountValueDisplay: "$19.20",
            unitPrice: 38.4,
            unitPriceDisplay: "$38.40",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "18,56 €",
            unitPrice: 37.44,
            unitPriceDisplay: "37,44 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 67,
        display: "$67.00",
        quantityDiscount: {
          1: {
            discountValue: 22.17,
            discountValueDisplay: "$22.17",
            unitPrice: 44.83,
            unitPriceDisplay: "$44.83",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "16,00 US$",
            unitPrice: 32,
            unitPriceDisplay: "32,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 50,
        display: "€ 50,00",
        quantityDiscount: {
          1: {
            discountValue: 16.66,
            discountValueDisplay: "€ 16,66",
            unitPrice: 33.34,
            unitPriceDisplay: "€ 33,34",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 57.12,
        display: "US$57,12",
        quantityDiscount: {
          1: {
            discountValue: 19.04,
            discountValueDisplay: "US$19,04",
            unitPrice: 38.08,
            unitPriceDisplay: "US$38,08",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 57.12,
        display: "US$ 57,12",
        quantityDiscount: {
          1: {
            discountValue: 19.04,
            discountValueDisplay: "US$ 19,04",
            unitPrice: 38.08,
            unitPriceDisplay: "US$ 38,08",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16,00",
            unitPrice: 32,
            unitPriceDisplay: "USD32,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 48,
        display: "48,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "16,00 US$",
            unitPrice: 32,
            unitPriceDisplay: "32,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 51.36,
        display: "$51.36",
        quantityDiscount: {
          1: {
            discountValue: 17.12,
            discountValueDisplay: "$17.12",
            unitPrice: 34.24,
            unitPriceDisplay: "$34.24",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 18.41,
            discountValueDisplay: "€18.41",
            unitPrice: 37.59,
            unitPriceDisplay: "€37.59",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "18,72 €",
            unitPrice: 38.28,
            unitPriceDisplay: "38,28 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 56.64,
        display: "$56,64",
        quantityDiscount: {
          1: {
            discountValue: 18.88,
            discountValueDisplay: "$18,88",
            unitPrice: 37.76,
            unitPriceDisplay: "$37,76",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.41,
            discountValueDisplay: "18,41 €",
            unitPrice: 37.59,
            unitPriceDisplay: "37,59 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 50.4,
        display: "US$50.40",
        quantityDiscount: {
          1: {
            discountValue: 16.8,
            discountValueDisplay: "US$16.80",
            unitPrice: 33.6,
            unitPriceDisplay: "US$33.60",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 59,
        display: "59,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.34,
            discountValueDisplay: "19,34 €",
            unitPrice: 39.66,
            unitPriceDisplay: "39,66 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 48,
        display: "$48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16,00",
            unitPrice: 32,
            unitPriceDisplay: "$32,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 56,
        display: "€56.00",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "€18.56",
            unitPrice: 37.44,
            unitPriceDisplay: "€37.44",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 48,
        display: "US$ 48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$ 16,00",
            unitPrice: 32,
            unitPriceDisplay: "US$ 32,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "18,72 €",
            unitPrice: 38.28,
            unitPriceDisplay: "38,28 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 48,
        display: "USD48,00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16,00",
            unitPrice: 32,
            unitPriceDisplay: "USD32,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.18,
            discountValueDisplay: "19,18 €",
            unitPrice: 38.82,
            unitPriceDisplay: "38,82 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 56,
        display: "56,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.56,
            discountValueDisplay: "18,56 €",
            unitPrice: 37.44,
            unitPriceDisplay: "37,44 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 50,
        display: "£50.00",
        quantityDiscount: {
          1: {
            discountValue: 16.45,
            discountValueDisplay: "£16.45",
            unitPrice: 33.55,
            unitPriceDisplay: "£33.55",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 13.71,
            discountValueDisplay: "£13.71",
            unitPrice: 28.29,
            unitPriceDisplay: "£28.29",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.18,
            discountValueDisplay: "19,18 €",
            unitPrice: 38.82,
            unitPriceDisplay: "38,82 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 13.71,
            discountValueDisplay: "£13.71",
            unitPrice: 28.29,
            unitPriceDisplay: "£28.29",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16.00",
            unitPrice: 32,
            unitPriceDisplay: "USD32.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 72,
        display: "A$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "A$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "A$48.04",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 59,
        display: "59,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 19.34,
            discountValueDisplay: "19,34 EUR",
            unitPrice: 39.66,
            unitPriceDisplay: "39,66 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 59,
        display: "59,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 19.65,
            discountValueDisplay: "19,65 EUR",
            unitPrice: 39.35,
            unitPriceDisplay: "39,35 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 47,
        display: "47,00 €",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "15,47 €",
            unitPrice: 31.53,
            unitPriceDisplay: "31,53 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 53.28,
        display: "$53.28",
        quantityDiscount: {
          1: {
            discountValue: 17.76,
            discountValueDisplay: "$17.76",
            unitPrice: 35.52,
            unitPriceDisplay: "$35.52",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 58,
        display: "€58.00",
        quantityDiscount: {
          1: {
            discountValue: 19.03,
            discountValueDisplay: "€19.03",
            unitPrice: 38.97,
            unitPriceDisplay: "€38.97",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 13.71,
            discountValueDisplay: "£13.71",
            unitPrice: 28.29,
            unitPriceDisplay: "£28.29",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 56.64,
        display: "US$ 56.64",
        quantityDiscount: {
          1: {
            discountValue: 18.88,
            discountValueDisplay: "US$ 18.88",
            unitPrice: 37.76,
            unitPriceDisplay: "US$ 37.76",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 55.2,
        display: "US$55,20",
        quantityDiscount: {
          1: {
            discountValue: 18.4,
            discountValueDisplay: "US$18,40",
            unitPrice: 36.8,
            unitPriceDisplay: "US$36,80",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 58,
        display: "€58.00",
        quantityDiscount: {
          1: {
            discountValue: 19.18,
            discountValueDisplay: "€19.18",
            unitPrice: 38.82,
            unitPriceDisplay: "€38.82",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.87,
            discountValueDisplay: "18,87 €",
            unitPrice: 38.13,
            unitPriceDisplay: "38,13 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 42,
        display: "£42.00",
        quantityDiscount: {
          1: {
            discountValue: 13.71,
            discountValueDisplay: "£13.71",
            unitPrice: 28.29,
            unitPriceDisplay: "£28.29",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 52.8,
        display: "$52.80",
        quantityDiscount: {
          1: {
            discountValue: 17.6,
            discountValueDisplay: "$17.60",
            unitPrice: 35.2,
            unitPriceDisplay: "$35.20",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 52.8,
        display: "US$52.80",
        quantityDiscount: {
          1: {
            discountValue: 17.6,
            discountValueDisplay: "US$17.60",
            unitPrice: 35.2,
            unitPriceDisplay: "US$35.20",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "18,72 €",
            unitPrice: 38.28,
            unitPriceDisplay: "38,28 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 55,
        display: "€55.00",
        quantityDiscount: {
          1: {
            discountValue: 18.1,
            discountValueDisplay: "€18.10",
            unitPrice: 36.9,
            unitPriceDisplay: "€36.90",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 57,
        display: "57,00 €",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "18,72 €",
            unitPrice: 38.28,
            unitPriceDisplay: "38,28 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 55,
        display: "€55.00",
        quantityDiscount: {
          1: {
            discountValue: 18.25,
            discountValueDisplay: "€18.25",
            unitPrice: 36.75,
            unitPriceDisplay: "€36.75",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16.00",
            unitPrice: 32,
            unitPriceDisplay: "USD32.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 57,
        display: "€ 57,00",
        quantityDiscount: {
          1: {
            discountValue: 18.72,
            discountValueDisplay: "€ 18,72",
            unitPrice: 38.28,
            unitPriceDisplay: "€ 38,28",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 59,
        display: "€ 59,00",
        quantityDiscount: {
          1: {
            discountValue: 19.34,
            discountValueDisplay: "€ 19,34",
            unitPrice: 39.66,
            unitPriceDisplay: "€ 39,66",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 72,
        display: "$72.00",
        quantityDiscount: {
          1: {
            discountValue: 23.96,
            discountValueDisplay: "$23.96",
            unitPrice: 48.04,
            unitPriceDisplay: "$48.04",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 55.2,
        display: "US$55.20",
        quantityDiscount: {
          1: {
            discountValue: 18.4,
            discountValueDisplay: "US$18.40",
            unitPrice: 36.8,
            unitPriceDisplay: "US$36.80",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16.00",
            unitPrice: 32,
            unitPriceDisplay: "USD32.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 48,
        display: "USD48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "USD16.00",
            unitPrice: 32,
            unitPriceDisplay: "USD32.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 48,
        display: "$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "$16.00",
            unitPrice: 32,
            unitPriceDisplay: "$32.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 58,
        display: "58,00 €",
        quantityDiscount: {
          1: {
            discountValue: 19.03,
            discountValueDisplay: "19,03 €",
            unitPrice: 38.97,
            unitPriceDisplay: "38,97 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 47,
        display: "€47.00",
        quantityDiscount: {
          1: {
            discountValue: 15.47,
            discountValueDisplay: "€15.47",
            unitPrice: 31.53,
            unitPriceDisplay: "€31.53",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 48,
        display: "US$48.00",
        quantityDiscount: {
          1: {
            discountValue: 16,
            discountValueDisplay: "US$16.00",
            unitPrice: 32,
            unitPriceDisplay: "US$32.00",
          },
        },
      },
    },
  },
  "pdf-monthly-multi-uk": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-multi-uk",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 8,
        display: "A$8.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-two-year-multi": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-two-year-multi",
    pricing: {
      PR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 229,
        display: "229,00 €",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "114,19 €",
            unitPrice: 114.81,
            unitPriceDisplay: "114,81 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 201.6,
        display: "$201.60",
        quantityDiscount: {
          1: {
            discountValue: 100.8,
            discountValueDisplay: "$100.80",
            unitPrice: 100.8,
            unitPriceDisplay: "$100.80",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 223,
        display: "€ 223,00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€ 111,41",
            unitPrice: 111.59,
            unitPriceDisplay: "€ 111,59",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 317,
        display: "$317.00",
        quantityDiscount: {
          1: {
            discountValue: 158.14,
            discountValueDisplay: "$158.14",
            unitPrice: 158.86,
            unitPriceDisplay: "$158.86",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 221,
        display: "€221.00",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "€110.48",
            unitPrice: 110.52,
            unitPriceDisplay: "€110.52",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 223,
        display: "223,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 EUR",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 230.4,
        display: "230,40 $",
        quantityDiscount: {
          1: {
            discountValue: 115.2,
            discountValueDisplay: "115,20 $",
            unitPrice: 115.2,
            unitPriceDisplay: "115,20 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 223,
        display: "€223.00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€111.41",
            unitPrice: 111.59,
            unitPriceDisplay: "€111.59",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 220.8,
        display: "$220.80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "$110.40",
            unitPrice: 110.4,
            unitPriceDisplay: "$110.40",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 233,
        display: "233,00 €",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 €",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 207.36,
        display: "US$207.36",
        quantityDiscount: {
          1: {
            discountValue: 103.68,
            discountValueDisplay: "US$103.68",
            unitPrice: 103.68,
            unitPriceDisplay: "US$103.68",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 227,
        display: "227,00 €",
        quantityDiscount: {
          1: {
            discountValue: 113.26,
            discountValueDisplay: "113,26 €",
            unitPrice: 113.74,
            unitPriceDisplay: "113,74 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 230.4,
        display: "$230.40",
        quantityDiscount: {
          1: {
            discountValue: 115.2,
            discountValueDisplay: "$115.20",
            unitPrice: 115.2,
            unitPriceDisplay: "$115.20",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 223,
        display: "223,00 €",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 €",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 267,
        display: "$267.00",
        quantityDiscount: {
          1: {
            discountValue: 133.04,
            discountValueDisplay: "$133.04",
            unitPrice: 133.96,
            unitPriceDisplay: "$133.96",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 200,
        display: "€ 200,00",
        quantityDiscount: {
          1: {
            discountValue: 99.99,
            discountValueDisplay: "€ 99,99",
            unitPrice: 100.01,
            unitPriceDisplay: "€ 100,01",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 228.48,
        display: "US$228,48",
        quantityDiscount: {
          1: {
            discountValue: 114.24,
            discountValueDisplay: "US$114,24",
            unitPrice: 114.24,
            unitPriceDisplay: "US$114,24",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 228.48,
        display: "US$ 228,48",
        quantityDiscount: {
          1: {
            discountValue: 114.24,
            discountValueDisplay: "US$ 114,24",
            unitPrice: 114.24,
            unitPriceDisplay: "US$ 114,24",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 205.44,
        display: "$205.44",
        quantityDiscount: {
          1: {
            discountValue: 102.72,
            discountValueDisplay: "$102.72",
            unitPrice: 102.72,
            unitPriceDisplay: "$102.72",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 221,
        display: "€221.00",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "€110.48",
            unitPrice: 110.52,
            unitPriceDisplay: "€110.52",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 226.56,
        display: "$226,56",
        quantityDiscount: {
          1: {
            discountValue: 113.28,
            discountValueDisplay: "$113,28",
            unitPrice: 113.28,
            unitPriceDisplay: "$113,28",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 221,
        display: "221,00 €",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "110,48 €",
            unitPrice: 110.52,
            unitPriceDisplay: "110,52 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 201.6,
        display: "US$201.60",
        quantityDiscount: {
          1: {
            discountValue: 100.8,
            discountValueDisplay: "US$100.80",
            unitPrice: 100.8,
            unitPriceDisplay: "US$100.80",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 233,
        display: "233,00 €",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 €",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 192,
        display: "$192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96,00",
            unitPrice: 96,
            unitPriceDisplay: "$96,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 223,
        display: "€223.00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€111.41",
            unitPrice: 111.59,
            unitPriceDisplay: "€111.59",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 231,
        display: "231,00 €",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "115,12 €",
            unitPrice: 115.88,
            unitPriceDisplay: "115,88 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 223,
        display: "223,00 €",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 €",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 198,
        display: "£198.00",
        quantityDiscount: {
          1: {
            discountValue: 98.74,
            discountValueDisplay: "£98.74",
            unitPrice: 99.26,
            unitPriceDisplay: "£99.26",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 231,
        display: "231,00 €",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "115,12 €",
            unitPrice: 115.88,
            unitPriceDisplay: "115,88 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 288,
        display: "A$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "A$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "A$144.24",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 233,
        display: "233,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 EUR",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 236,
        display: "236,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 117.91,
            discountValueDisplay: "117,91 EUR",
            unitPrice: 118.09,
            unitPriceDisplay: "118,09 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 186,
        display: "186,00 €",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "92,84 €",
            unitPrice: 93.16,
            unitPriceDisplay: "93,16 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 213.12,
        display: "$213.12",
        quantityDiscount: {
          1: {
            discountValue: 106.56,
            discountValueDisplay: "$106.56",
            unitPrice: 106.56,
            unitPriceDisplay: "$106.56",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 229,
        display: "€229.00",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "€114.19",
            unitPrice: 114.81,
            unitPriceDisplay: "€114.81",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 226.56,
        display: "US$ 226.56",
        quantityDiscount: {
          1: {
            discountValue: 113.28,
            discountValueDisplay: "US$ 113.28",
            unitPrice: 113.28,
            unitPriceDisplay: "US$ 113.28",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 220.8,
        display: "US$220,80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "US$110,40",
            unitPrice: 110.4,
            unitPriceDisplay: "US$110,40",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 231,
        display: "€231.00",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "€115.12",
            unitPrice: 115.88,
            unitPriceDisplay: "€115.88",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 227,
        display: "227,00 €",
        quantityDiscount: {
          1: {
            discountValue: 113.26,
            discountValueDisplay: "113,26 €",
            unitPrice: 113.74,
            unitPriceDisplay: "113,74 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 211.2,
        display: "$211.20",
        quantityDiscount: {
          1: {
            discountValue: 105.6,
            discountValueDisplay: "$105.60",
            unitPrice: 105.6,
            unitPriceDisplay: "$105.60",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 211.2,
        display: "US$211.20",
        quantityDiscount: {
          1: {
            discountValue: 105.6,
            discountValueDisplay: "US$105.60",
            unitPrice: 105.6,
            unitPriceDisplay: "US$105.60",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 218,
        display: "€218.00",
        quantityDiscount: {
          1: {
            discountValue: 108.62,
            discountValueDisplay: "€108.62",
            unitPrice: 109.38,
            unitPriceDisplay: "€109.38",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 220,
        display: "€220.00",
        quantityDiscount: {
          1: {
            discountValue: 109.55,
            discountValueDisplay: "€109.55",
            unitPrice: 110.45,
            unitPriceDisplay: "€110.45",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 225,
        display: "€ 225,00",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "€ 112,34",
            unitPrice: 112.66,
            unitPriceDisplay: "€ 112,66",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 233,
        display: "€ 233,00",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "€ 116,05",
            unitPrice: 116.95,
            unitPriceDisplay: "€ 116,95",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 220.8,
        display: "US$220.80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "US$110.40",
            unitPrice: 110.4,
            unitPriceDisplay: "US$110.40",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 229,
        display: "229,00 €",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "114,19 €",
            unitPrice: 114.81,
            unitPriceDisplay: "114,81 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
    },
  },
  "pdf-perpetual-multi-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-perpetual-multi-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 264.6,
        display: "$264.60",
        quantityDiscount: {
          1: {
            discountValue: 31.5,
            discountValueDisplay: "$31.50",
            unitPrice: 233.1,
            unitPriceDisplay: "$233.10",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 302.4,
        display: "302,40 $",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 $",
            unitPrice: 266.4,
            unitPriceDisplay: "266,40 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 289.8,
        display: "$289.80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "$34.50",
            unitPrice: 255.3,
            unitPriceDisplay: "$255.30",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 272.16,
        display: "US$272.16",
        quantityDiscount: {
          1: {
            discountValue: 32.4,
            discountValueDisplay: "US$32.40",
            unitPrice: 239.76,
            unitPriceDisplay: "US$239.76",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 302.4,
        display: "$302.40",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 266.4,
            unitPriceDisplay: "$266.40",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 299.88,
        display: "US$299,88",
        quantityDiscount: {
          1: {
            discountValue: 35.7,
            discountValueDisplay: "US$35,70",
            unitPrice: 264.18,
            unitPriceDisplay: "US$264,18",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 299.88,
        display: "US$ 299,88",
        quantityDiscount: {
          1: {
            discountValue: 35.7,
            discountValueDisplay: "US$ 35,70",
            unitPrice: 264.18,
            unitPriceDisplay: "US$ 264,18",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 269.64,
        display: "$269.64",
        quantityDiscount: {
          1: {
            discountValue: 32.1,
            discountValueDisplay: "$32.10",
            unitPrice: 237.54,
            unitPriceDisplay: "$237.54",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 297.36,
        display: "$297,36",
        quantityDiscount: {
          1: {
            discountValue: 35.4,
            discountValueDisplay: "$35,40",
            unitPrice: 261.96,
            unitPriceDisplay: "$261,96",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 264.6,
        display: "US$264.60",
        quantityDiscount: {
          1: {
            discountValue: 31.5,
            discountValueDisplay: "US$31.50",
            unitPrice: 233.1,
            unitPriceDisplay: "US$233.10",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 252,
        display: "$252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30,00",
            unitPrice: 222,
            unitPriceDisplay: "$222,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 321,
        display: "A$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "A$36.00",
            unitPrice: 285,
            unitPriceDisplay: "A$285.00",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 279.72,
        display: "$279.72",
        quantityDiscount: {
          1: {
            discountValue: 33.3,
            discountValueDisplay: "$33.30",
            unitPrice: 246.42,
            unitPriceDisplay: "$246.42",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 297.36,
        display: "US$ 297.36",
        quantityDiscount: {
          1: {
            discountValue: 35.4,
            discountValueDisplay: "US$ 35.40",
            unitPrice: 261.96,
            unitPriceDisplay: "US$ 261.96",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 289.8,
        display: "US$289,80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "US$34,50",
            unitPrice: 255.3,
            unitPriceDisplay: "US$255,30",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 277.2,
        display: "$277.20",
        quantityDiscount: {
          1: {
            discountValue: 33,
            discountValueDisplay: "$33.00",
            unitPrice: 244.2,
            unitPriceDisplay: "$244.20",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 277.2,
        display: "US$277.20",
        quantityDiscount: {
          1: {
            discountValue: 33,
            discountValueDisplay: "US$33.00",
            unitPrice: 244.2,
            unitPriceDisplay: "US$244.20",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 267.12,
        display: "US$267.12",
        quantityDiscount: {
          1: {
            discountValue: 31.8,
            discountValueDisplay: "US$31.80",
            unitPrice: 235.32,
            unitPriceDisplay: "US$235.32",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 289.8,
        display: "US$289.80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "US$34.50",
            unitPrice: 255.3,
            unitPriceDisplay: "US$255.30",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
    },
  },
  "perpetual-pdf": {
    action: "product.price.getall",
    result: "success",
    product: "perpetual-pdf",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-two-year-multi-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-two-year-multi-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 315,
        display: "315,00 €",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "157,01 €",
            unitPrice: 157.99,
            unitPriceDisplay: "157,99 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 277.2,
        display: "$277.20",
        quantityDiscount: {
          1: {
            discountValue: 138.6,
            discountValueDisplay: "$138.60",
            unitPrice: 138.6,
            unitPriceDisplay: "$138.60",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 307,
        display: "€ 307,00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€ 153,18",
            unitPrice: 153.82,
            unitPriceDisplay: "€ 153,82",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 435,
        display: "$435.00",
        quantityDiscount: {
          1: {
            discountValue: 217.43,
            discountValueDisplay: "$217.43",
            unitPrice: 217.57,
            unitPriceDisplay: "$217.57",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 304,
        display: "€304.00",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "€151.90",
            unitPrice: 152.1,
            unitPriceDisplay: "€152.10",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 307,
        display: "307,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 EUR",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 316.8,
        display: "316,80 $",
        quantityDiscount: {
          1: {
            discountValue: 158.4,
            discountValueDisplay: "158,40 $",
            unitPrice: 158.4,
            unitPriceDisplay: "158,40 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 307,
        display: "€307.00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€153.18",
            unitPrice: 153.82,
            unitPriceDisplay: "€153.82",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 303.6,
        display: "$303.60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "$151.80",
            unitPrice: 151.8,
            unitPriceDisplay: "$151.80",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 320,
        display: "320,00 €",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 €",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 285.12,
        display: "US$285.12",
        quantityDiscount: {
          1: {
            discountValue: 142.56,
            discountValueDisplay: "US$142.56",
            unitPrice: 142.56,
            unitPriceDisplay: "US$142.56",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 312,
        display: "312,00 €",
        quantityDiscount: {
          1: {
            discountValue: 155.73,
            discountValueDisplay: "155,73 €",
            unitPrice: 156.27,
            unitPriceDisplay: "156,27 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 316.8,
        display: "$316.80",
        quantityDiscount: {
          1: {
            discountValue: 158.4,
            discountValueDisplay: "$158.40",
            unitPrice: 158.4,
            unitPriceDisplay: "$158.40",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 307,
        display: "307,00 €",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 €",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 366,
        display: "$366.00",
        quantityDiscount: {
          1: {
            discountValue: 182.93,
            discountValueDisplay: "$182.93",
            unitPrice: 183.07,
            unitPriceDisplay: "$183.07",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 275,
        display: "€ 275,00",
        quantityDiscount: {
          1: {
            discountValue: 137.48,
            discountValueDisplay: "€ 137,48",
            unitPrice: 137.52,
            unitPriceDisplay: "€ 137,52",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 314.16,
        display: "US$314,16",
        quantityDiscount: {
          1: {
            discountValue: 157.08,
            discountValueDisplay: "US$157,08",
            unitPrice: 157.08,
            unitPriceDisplay: "US$157,08",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 314.16,
        display: "US$ 314,16",
        quantityDiscount: {
          1: {
            discountValue: 157.08,
            discountValueDisplay: "US$ 157,08",
            unitPrice: 157.08,
            unitPriceDisplay: "US$ 157,08",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 264,
        display: "264,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "132,00 US$",
            unitPrice: 132,
            unitPriceDisplay: "132,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 282.48,
        display: "$282.48",
        quantityDiscount: {
          1: {
            discountValue: 141.24,
            discountValueDisplay: "$141.24",
            unitPrice: 141.24,
            unitPriceDisplay: "$141.24",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 304,
        display: "€304.00",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "€151.90",
            unitPrice: 152.1,
            unitPriceDisplay: "€152.10",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 311.52,
        display: "$311,52",
        quantityDiscount: {
          1: {
            discountValue: 155.76,
            discountValueDisplay: "$155,76",
            unitPrice: 155.76,
            unitPriceDisplay: "$155,76",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 304,
        display: "304,00 €",
        quantityDiscount: {
          1: {
            discountValue: 151.9,
            discountValueDisplay: "151,90 €",
            unitPrice: 152.1,
            unitPriceDisplay: "152,10 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 277.2,
        display: "US$277.20",
        quantityDiscount: {
          1: {
            discountValue: 138.6,
            discountValueDisplay: "US$138.60",
            unitPrice: 138.6,
            unitPriceDisplay: "US$138.60",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 320,
        display: "320,00 €",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 €",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 264,
        display: "$264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132,00",
            unitPrice: 132,
            unitPriceDisplay: "$132,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 307,
        display: "€307.00",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "€153.18",
            unitPrice: 153.82,
            unitPriceDisplay: "€153.82",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 264,
        display: "US$ 264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$ 132,00",
            unitPrice: 132,
            unitPriceDisplay: "US$ 132,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 264,
        display: "USD264,00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132,00",
            unitPrice: 132,
            unitPriceDisplay: "USD132,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 317,
        display: "317,00 €",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "158,29 €",
            unitPrice: 158.71,
            unitPriceDisplay: "158,71 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 307,
        display: "307,00 €",
        quantityDiscount: {
          1: {
            discountValue: 153.18,
            discountValueDisplay: "153,18 €",
            unitPrice: 153.82,
            unitPriceDisplay: "153,82 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 272,
        display: "£272.00",
        quantityDiscount: {
          1: {
            discountValue: 135.77,
            discountValueDisplay: "£135.77",
            unitPrice: 136.23,
            unitPriceDisplay: "£136.23",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 317,
        display: "317,00 €",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "158,29 €",
            unitPrice: 158.71,
            unitPriceDisplay: "158,71 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 396,
        display: "A$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "A$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "A$198.34",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 320,
        display: "320,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "159,56 EUR",
            unitPrice: 160.44,
            unitPriceDisplay: "160,44 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 325,
        display: "325,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 162.12,
            discountValueDisplay: "162,12 EUR",
            unitPrice: 162.88,
            unitPriceDisplay: "162,88 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 256,
        display: "256,00 €",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "127,65 €",
            unitPrice: 128.35,
            unitPriceDisplay: "128,35 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 293.04,
        display: "$293.04",
        quantityDiscount: {
          1: {
            discountValue: 146.52,
            discountValueDisplay: "$146.52",
            unitPrice: 146.52,
            unitPriceDisplay: "$146.52",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 315,
        display: "€315.00",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "€157.01",
            unitPrice: 157.99,
            unitPriceDisplay: "€157.99",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 311.52,
        display: "US$ 311.52",
        quantityDiscount: {
          1: {
            discountValue: 155.76,
            discountValueDisplay: "US$ 155.76",
            unitPrice: 155.76,
            unitPriceDisplay: "US$ 155.76",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 303.6,
        display: "US$303,60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "US$151,80",
            unitPrice: 151.8,
            unitPriceDisplay: "US$151,80",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 317,
        display: "€317.00",
        quantityDiscount: {
          1: {
            discountValue: 158.29,
            discountValueDisplay: "€158.29",
            unitPrice: 158.71,
            unitPriceDisplay: "€158.71",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 312,
        display: "312,00 €",
        quantityDiscount: {
          1: {
            discountValue: 155.73,
            discountValueDisplay: "155,73 €",
            unitPrice: 156.27,
            unitPriceDisplay: "156,27 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 227,
        display: "£227.00",
        quantityDiscount: {
          1: {
            discountValue: 113.14,
            discountValueDisplay: "£113.14",
            unitPrice: 113.86,
            unitPriceDisplay: "£113.86",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 290.4,
        display: "$290.40",
        quantityDiscount: {
          1: {
            discountValue: 145.2,
            discountValueDisplay: "$145.20",
            unitPrice: 145.2,
            unitPriceDisplay: "$145.20",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 290.4,
        display: "US$290.40",
        quantityDiscount: {
          1: {
            discountValue: 145.2,
            discountValueDisplay: "US$145.20",
            unitPrice: 145.2,
            unitPriceDisplay: "US$145.20",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 299,
        display: "€299.00",
        quantityDiscount: {
          1: {
            discountValue: 149.35,
            discountValueDisplay: "€149.35",
            unitPrice: 149.65,
            unitPriceDisplay: "€149.65",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 309,
        display: "309,00 €",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "154,46 €",
            unitPrice: 154.54,
            unitPriceDisplay: "154,54 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 302,
        display: "€302.00",
        quantityDiscount: {
          1: {
            discountValue: 150.63,
            discountValueDisplay: "€150.63",
            unitPrice: 151.37,
            unitPriceDisplay: "€151.37",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 309,
        display: "€ 309,00",
        quantityDiscount: {
          1: {
            discountValue: 154.46,
            discountValueDisplay: "€ 154,46",
            unitPrice: 154.54,
            unitPriceDisplay: "€ 154,54",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 320,
        display: "€ 320,00",
        quantityDiscount: {
          1: {
            discountValue: 159.56,
            discountValueDisplay: "€ 159,56",
            unitPrice: 160.44,
            unitPriceDisplay: "€ 160,44",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 396,
        display: "$396.00",
        quantityDiscount: {
          1: {
            discountValue: 197.66,
            discountValueDisplay: "$197.66",
            unitPrice: 198.34,
            unitPriceDisplay: "$198.34",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 303.6,
        display: "US$303.60",
        quantityDiscount: {
          1: {
            discountValue: 151.8,
            discountValueDisplay: "US$151.80",
            unitPrice: 151.8,
            unitPriceDisplay: "US$151.80",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 264,
        display: "USD264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "USD132.00",
            unitPrice: 132,
            unitPriceDisplay: "USD132.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 264,
        display: "$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "$132.00",
            unitPrice: 132,
            unitPriceDisplay: "$132.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 315,
        display: "315,00 €",
        quantityDiscount: {
          1: {
            discountValue: 157.01,
            discountValueDisplay: "157,01 €",
            unitPrice: 157.99,
            unitPriceDisplay: "157,99 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 256,
        display: "€256.00",
        quantityDiscount: {
          1: {
            discountValue: 127.65,
            discountValueDisplay: "€127.65",
            unitPrice: 128.35,
            unitPriceDisplay: "€128.35",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 264,
        display: "US$264.00",
        quantityDiscount: {
          1: {
            discountValue: 132,
            discountValueDisplay: "US$132.00",
            unitPrice: 132,
            unitPriceDisplay: "US$132.00",
          },
        },
      },
    },
  },
  ocr: {
    action: "product.price.getall",
    result: "success",
    product: "ocr",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "lite-feature": {
    action: "product.price.getall",
    result: "success",
    product: "lite-feature",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-pro-lite-m": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-pro-lite-m",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  var2: {
    action: "product.price.getall",
    result: "success",
    product: "var2",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-two-year": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-two-year",
    pricing: {
      PR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 229,
        display: "229,00 €",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "114,19 €",
            unitPrice: 114.81,
            unitPriceDisplay: "114,81 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 201.6,
        display: "$201.60",
        quantityDiscount: {
          1: {
            discountValue: 100.8,
            discountValueDisplay: "$100.80",
            unitPrice: 100.8,
            unitPriceDisplay: "$100.80",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 223,
        display: "€ 223,00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€ 111,41",
            unitPrice: 111.59,
            unitPriceDisplay: "€ 111,59",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 317,
        display: "$317.00",
        quantityDiscount: {
          1: {
            discountValue: 158.14,
            discountValueDisplay: "$158.14",
            unitPrice: 158.86,
            unitPriceDisplay: "$158.86",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 221,
        display: "€221.00",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "€110.48",
            unitPrice: 110.52,
            unitPriceDisplay: "€110.52",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 223,
        display: "223,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 EUR",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 230.4,
        display: "230,40 $",
        quantityDiscount: {
          1: {
            discountValue: 115.2,
            discountValueDisplay: "115,20 $",
            unitPrice: 115.2,
            unitPriceDisplay: "115,20 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 223,
        display: "€223.00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€111.41",
            unitPrice: 111.59,
            unitPriceDisplay: "€111.59",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 220.8,
        display: "$220.80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "$110.40",
            unitPrice: 110.4,
            unitPriceDisplay: "$110.40",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 233,
        display: "233,00 €",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 €",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 207.36,
        display: "US$207.36",
        quantityDiscount: {
          1: {
            discountValue: 103.68,
            discountValueDisplay: "US$103.68",
            unitPrice: 103.68,
            unitPriceDisplay: "US$103.68",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 227,
        display: "227,00 €",
        quantityDiscount: {
          1: {
            discountValue: 113.26,
            discountValueDisplay: "113,26 €",
            unitPrice: 113.74,
            unitPriceDisplay: "113,74 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 230.4,
        display: "$230.40",
        quantityDiscount: {
          1: {
            discountValue: 115.2,
            discountValueDisplay: "$115.20",
            unitPrice: 115.2,
            unitPriceDisplay: "$115.20",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 223,
        display: "223,00 €",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 €",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 267,
        display: "$267.00",
        quantityDiscount: {
          1: {
            discountValue: 133.04,
            discountValueDisplay: "$133.04",
            unitPrice: 133.96,
            unitPriceDisplay: "$133.96",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 200,
        display: "€ 200,00",
        quantityDiscount: {
          1: {
            discountValue: 99.99,
            discountValueDisplay: "€ 99,99",
            unitPrice: 100.01,
            unitPriceDisplay: "€ 100,01",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 228.48,
        display: "US$228,48",
        quantityDiscount: {
          1: {
            discountValue: 114.24,
            discountValueDisplay: "US$114,24",
            unitPrice: 114.24,
            unitPriceDisplay: "US$114,24",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 228.48,
        display: "US$ 228,48",
        quantityDiscount: {
          1: {
            discountValue: 114.24,
            discountValueDisplay: "US$ 114,24",
            unitPrice: 114.24,
            unitPriceDisplay: "US$ 114,24",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 192,
        display: "192,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "96,00 US$",
            unitPrice: 96,
            unitPriceDisplay: "96,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 205.44,
        display: "$205.44",
        quantityDiscount: {
          1: {
            discountValue: 102.72,
            discountValueDisplay: "$102.72",
            unitPrice: 102.72,
            unitPriceDisplay: "$102.72",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 221,
        display: "€221.00",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "€110.48",
            unitPrice: 110.52,
            unitPriceDisplay: "€110.52",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 226.56,
        display: "$226,56",
        quantityDiscount: {
          1: {
            discountValue: 113.28,
            discountValueDisplay: "$113,28",
            unitPrice: 113.28,
            unitPriceDisplay: "$113,28",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 221,
        display: "221,00 €",
        quantityDiscount: {
          1: {
            discountValue: 110.48,
            discountValueDisplay: "110,48 €",
            unitPrice: 110.52,
            unitPriceDisplay: "110,52 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 201.6,
        display: "US$201.60",
        quantityDiscount: {
          1: {
            discountValue: 100.8,
            discountValueDisplay: "US$100.80",
            unitPrice: 100.8,
            unitPriceDisplay: "US$100.80",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 233,
        display: "233,00 €",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 €",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 192,
        display: "$192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96,00",
            unitPrice: 96,
            unitPriceDisplay: "$96,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 223,
        display: "€223.00",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "€111.41",
            unitPrice: 111.59,
            unitPriceDisplay: "€111.59",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 192,
        display: "US$ 192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$ 96,00",
            unitPrice: 96,
            unitPriceDisplay: "US$ 96,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 192,
        display: "USD192,00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96,00",
            unitPrice: 96,
            unitPriceDisplay: "USD96,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 231,
        display: "231,00 €",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "115,12 €",
            unitPrice: 115.88,
            unitPriceDisplay: "115,88 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 223,
        display: "223,00 €",
        quantityDiscount: {
          1: {
            discountValue: 111.41,
            discountValueDisplay: "111,41 €",
            unitPrice: 111.59,
            unitPriceDisplay: "111,59 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 198,
        display: "£198.00",
        quantityDiscount: {
          1: {
            discountValue: 98.74,
            discountValueDisplay: "£98.74",
            unitPrice: 99.26,
            unitPriceDisplay: "£99.26",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 231,
        display: "231,00 €",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "115,12 €",
            unitPrice: 115.88,
            unitPriceDisplay: "115,88 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 288,
        display: "A$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "A$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "A$144.24",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 233,
        display: "233,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "116,05 EUR",
            unitPrice: 116.95,
            unitPriceDisplay: "116,95 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 236,
        display: "236,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 117.91,
            discountValueDisplay: "117,91 EUR",
            unitPrice: 118.09,
            unitPriceDisplay: "118,09 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 186,
        display: "186,00 €",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "92,84 €",
            unitPrice: 93.16,
            unitPriceDisplay: "93,16 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 213.12,
        display: "$213.12",
        quantityDiscount: {
          1: {
            discountValue: 106.56,
            discountValueDisplay: "$106.56",
            unitPrice: 106.56,
            unitPriceDisplay: "$106.56",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 229,
        display: "€229.00",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "€114.19",
            unitPrice: 114.81,
            unitPriceDisplay: "€114.81",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 226.56,
        display: "US$ 226.56",
        quantityDiscount: {
          1: {
            discountValue: 113.28,
            discountValueDisplay: "US$ 113.28",
            unitPrice: 113.28,
            unitPriceDisplay: "US$ 113.28",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 220.8,
        display: "US$220,80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "US$110,40",
            unitPrice: 110.4,
            unitPriceDisplay: "US$110,40",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 231,
        display: "€231.00",
        quantityDiscount: {
          1: {
            discountValue: 115.12,
            discountValueDisplay: "€115.12",
            unitPrice: 115.88,
            unitPriceDisplay: "€115.88",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 227,
        display: "227,00 €",
        quantityDiscount: {
          1: {
            discountValue: 113.26,
            discountValueDisplay: "113,26 €",
            unitPrice: 113.74,
            unitPriceDisplay: "113,74 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 165,
        display: "£165.00",
        quantityDiscount: {
          1: {
            discountValue: 82.28,
            discountValueDisplay: "£82.28",
            unitPrice: 82.72,
            unitPriceDisplay: "£82.72",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 211.2,
        display: "$211.20",
        quantityDiscount: {
          1: {
            discountValue: 105.6,
            discountValueDisplay: "$105.60",
            unitPrice: 105.6,
            unitPriceDisplay: "$105.60",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 211.2,
        display: "US$211.20",
        quantityDiscount: {
          1: {
            discountValue: 105.6,
            discountValueDisplay: "US$105.60",
            unitPrice: 105.6,
            unitPriceDisplay: "US$105.60",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 218,
        display: "€218.00",
        quantityDiscount: {
          1: {
            discountValue: 108.62,
            discountValueDisplay: "€108.62",
            unitPrice: 109.38,
            unitPriceDisplay: "€109.38",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 225,
        display: "225,00 €",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "112,34 €",
            unitPrice: 112.66,
            unitPriceDisplay: "112,66 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 220,
        display: "€220.00",
        quantityDiscount: {
          1: {
            discountValue: 109.55,
            discountValueDisplay: "€109.55",
            unitPrice: 110.45,
            unitPriceDisplay: "€110.45",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 225,
        display: "€ 225,00",
        quantityDiscount: {
          1: {
            discountValue: 112.34,
            discountValueDisplay: "€ 112,34",
            unitPrice: 112.66,
            unitPriceDisplay: "€ 112,66",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 233,
        display: "€ 233,00",
        quantityDiscount: {
          1: {
            discountValue: 116.05,
            discountValueDisplay: "€ 116,05",
            unitPrice: 116.95,
            unitPriceDisplay: "€ 116,95",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 288,
        display: "$288.00",
        quantityDiscount: {
          1: {
            discountValue: 143.76,
            discountValueDisplay: "$143.76",
            unitPrice: 144.24,
            unitPriceDisplay: "$144.24",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 220.8,
        display: "US$220.80",
        quantityDiscount: {
          1: {
            discountValue: 110.4,
            discountValueDisplay: "US$110.40",
            unitPrice: 110.4,
            unitPriceDisplay: "US$110.40",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 192,
        display: "USD192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "USD96.00",
            unitPrice: 96,
            unitPriceDisplay: "USD96.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 192,
        display: "$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "$96.00",
            unitPrice: 96,
            unitPriceDisplay: "$96.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 229,
        display: "229,00 €",
        quantityDiscount: {
          1: {
            discountValue: 114.19,
            discountValueDisplay: "114,19 €",
            unitPrice: 114.81,
            unitPriceDisplay: "114,81 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 186,
        display: "€186.00",
        quantityDiscount: {
          1: {
            discountValue: 92.84,
            discountValueDisplay: "€92.84",
            unitPrice: 93.16,
            unitPriceDisplay: "€93.16",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 192,
        display: "US$192.00",
        quantityDiscount: {
          1: {
            discountValue: 96,
            discountValueDisplay: "US$96.00",
            unitPrice: 96,
            unitPriceDisplay: "US$96.00",
          },
        },
      },
    },
  },
  var1: {
    action: "product.price.getall",
    result: "success",
    product: "var1",
    pricing: {
      PR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 0,
        display: "0,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      SA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 0, display: "$0.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 0,
        display: "0,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CX: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      TR: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      TT: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 0, display: "$0,00", quantityDiscount: {} },
      US: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 0,
        display: "US$ 0,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 0,
        display: "USD0,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 0,
        display: "A$0.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 0,
        display: "0,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ID: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      YT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 0,
        display: "US$ 0.00",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 0,
        display: "US$0,00",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 0, display: "£0.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      JP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KE: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 0,
        display: "€ 0,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 0, display: "$0.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 0,
        display: "USD0.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 0, display: "$0.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 0,
        display: "0,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 0, display: "€0.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 0,
        display: "US$0.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-monthly-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PS: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      QA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AD: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AE: {
        currency: "USD",
        price: 21,
        display: "$21.00",
        quantityDiscount: {},
      },
      AF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      AL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AO: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      AQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AR: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      AS: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AT: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      RE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AU: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      AW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      AX: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      AZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      RO: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BA: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BB: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      BD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 24,
        display: "24,00 $",
        quantityDiscount: {},
      },
      BF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BG: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      RW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BL: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      BM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BO: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      BQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SB: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      BT: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      BV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SG: {
        currency: "USD",
        price: 21.6,
        display: "US$21.60",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      BY: {
        currency: "USD",
        price: 24,
        display: "$24.00",
        quantityDiscount: {},
      },
      SJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      BZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      SM: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      SN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CA: {
        currency: "CAD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      SR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CC: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ST: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      CF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CH: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      SZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 23.8,
        display: "US$23,80",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 23.8,
        display: "US$ 23,80",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CU: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      CV: {
        currency: "USD",
        price: 20,
        display: "20,00 US$",
        quantityDiscount: {},
      },
      TG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TH: {
        currency: "USD",
        price: 21.4,
        display: "$21.40",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      CY: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      TJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      CZ: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 23.6,
        display: "$23,60",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 21,
        display: "US$21.00",
        quantityDiscount: {},
      },
      DJ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      TZ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      UA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      DZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EC: {
        currency: "USD",
        price: 20,
        display: "$20,00",
        quantityDiscount: {},
      },
      US: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      EG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      EH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      UY: {
        currency: "USD",
        price: 20,
        display: "US$ 20,00",
        quantityDiscount: {},
      },
      UZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VA: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      ER: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ET: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VE: {
        currency: "USD",
        price: 20,
        display: "USD20,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      VI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      VU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      FO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      FR: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      WF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GB: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      WS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GG: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      GH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GL: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      GN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GP: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      GQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GR: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      GS: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      GT: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      GU: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      GY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 23,
        display: "A$23.00",
        quantityDiscount: {},
      },
      HN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      HR: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      HT: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      YE: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      HU: {
        currency: "EUR",
        price: 21,
        display: "21,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 22.2,
        display: "$22.20",
        quantityDiscount: {},
      },
      YT: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IE: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IL: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      IM: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      IN: {
        currency: "USD",
        price: 23.6,
        display: "US$ 23.60",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 23,
        display: "US$23,00",
        quantityDiscount: {},
      },
      IQ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      IR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      IS: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      IT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JE: {
        currency: "GBP",
        price: 20,
        display: "£20.00",
        quantityDiscount: {},
      },
      ZW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      JO: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      JP: {
        currency: "USD",
        price: 22,
        display: "$22.00",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KG: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KI: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      KM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KR: {
        currency: "USD",
        price: 22,
        display: "US$22.00",
        quantityDiscount: {},
      },
      KW: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      KY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      KZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LB: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LC: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LI: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      LK: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      LR: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      LU: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      LV: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      LY: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MA: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MC: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MD: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ME: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MF: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MH: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MK: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      ML: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MN: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MO: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MQ: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MR: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MS: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MT: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      MU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MV: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      MW: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      MZ: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NA: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NC: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NE: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NF: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NI: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NL: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 21,
        display: "€ 21,00",
        quantityDiscount: {},
      },
      NP: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      NR: {
        currency: "AUD",
        price: 23,
        display: "$23.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 23,
        display: "US$23.00",
        quantityDiscount: {},
      },
      OM: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PA: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 20,
        display: "USD20.00",
        quantityDiscount: {},
      },
      PF: {
        currency: "USD",
        price: 20,
        display: "$20.00",
        quantityDiscount: {},
      },
      PG: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 21,
        display: "21,00 €",
        quantityDiscount: {},
      },
      PM: {
        currency: "EUR",
        price: 21,
        display: "€21.00",
        quantityDiscount: {},
      },
      PN: {
        currency: "USD",
        price: 20,
        display: "US$20.00",
        quantityDiscount: {},
      },
    },
  },
  "pdf-one-year-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-one-year-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 138.6,
        display: "$138.60",
        quantityDiscount: {
          1: {
            discountValue: 56.7,
            discountValueDisplay: "$56.70",
            unitPrice: 81.9,
            unitPriceDisplay: "$81.90",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 158.4,
        display: "158,40 $",
        quantityDiscount: {
          1: {
            discountValue: 64.8,
            discountValueDisplay: "64,80 $",
            unitPrice: 93.6,
            unitPriceDisplay: "93,60 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 151.8,
        display: "$151.80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "$62.10",
            unitPrice: 89.7,
            unitPriceDisplay: "$89.70",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 142.56,
        display: "US$142.56",
        quantityDiscount: {
          1: {
            discountValue: 58.32,
            discountValueDisplay: "US$58.32",
            unitPrice: 84.24,
            unitPriceDisplay: "US$84.24",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 158.4,
        display: "$158.40",
        quantityDiscount: {
          1: {
            discountValue: 64.8,
            discountValueDisplay: "$64.80",
            unitPrice: 93.6,
            unitPriceDisplay: "$93.60",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 157.08,
        display: "US$157,08",
        quantityDiscount: {
          1: {
            discountValue: 64.26,
            discountValueDisplay: "US$64,26",
            unitPrice: 92.82,
            unitPriceDisplay: "US$92,82",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 157.08,
        display: "US$ 157,08",
        quantityDiscount: {
          1: {
            discountValue: 64.26,
            discountValueDisplay: "US$ 64,26",
            unitPrice: 92.82,
            unitPriceDisplay: "US$ 92,82",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 132,
        display: "132,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "54,00 US$",
            unitPrice: 78,
            unitPriceDisplay: "78,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 141.24,
        display: "$141.24",
        quantityDiscount: {
          1: {
            discountValue: 57.78,
            discountValueDisplay: "$57.78",
            unitPrice: 83.46,
            unitPriceDisplay: "$83.46",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 155.76,
        display: "$155,76",
        quantityDiscount: {
          1: {
            discountValue: 63.72,
            discountValueDisplay: "$63,72",
            unitPrice: 92.04,
            unitPriceDisplay: "$92,04",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 138.6,
        display: "US$138.60",
        quantityDiscount: {
          1: {
            discountValue: 56.7,
            discountValueDisplay: "US$56.70",
            unitPrice: 81.9,
            unitPriceDisplay: "US$81.90",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 132,
        display: "$132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54,00",
            unitPrice: 78,
            unitPriceDisplay: "$78,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 132,
        display: "US$ 132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$ 54,00",
            unitPrice: 78,
            unitPriceDisplay: "US$ 78,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 132,
        display: "USD132,00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54,00",
            unitPrice: 78,
            unitPriceDisplay: "USD78,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 159,
        display: "A$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "A$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "A$91.50",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 147,
        display: "147,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 EUR",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 146.52,
        display: "$146.52",
        quantityDiscount: {
          1: {
            discountValue: 59.94,
            discountValueDisplay: "$59.94",
            unitPrice: 86.58,
            unitPriceDisplay: "$86.58",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 155.76,
        display: "US$ 155.76",
        quantityDiscount: {
          1: {
            discountValue: 63.72,
            discountValueDisplay: "US$ 63.72",
            unitPrice: 92.04,
            unitPriceDisplay: "US$ 92.04",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 151.8,
        display: "US$151,80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "US$62,10",
            unitPrice: 89.7,
            unitPriceDisplay: "US$89,70",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 132,
        display: "£132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "£54.00",
            unitPrice: 78,
            unitPriceDisplay: "£78.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 145.2,
        display: "$145.20",
        quantityDiscount: {
          1: {
            discountValue: 59.4,
            discountValueDisplay: "$59.40",
            unitPrice: 85.8,
            unitPriceDisplay: "$85.80",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 145.2,
        display: "US$145.20",
        quantityDiscount: {
          1: {
            discountValue: 59.4,
            discountValueDisplay: "US$59.40",
            unitPrice: 85.8,
            unitPriceDisplay: "US$85.80",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 147,
        display: "€ 147,00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€ 55,50",
            unitPrice: 91.5,
            unitPriceDisplay: "€ 91,50",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 159,
        display: "$159.00",
        quantityDiscount: {
          1: {
            discountValue: 67.5,
            discountValueDisplay: "$67.50",
            unitPrice: 91.5,
            unitPriceDisplay: "$91.50",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 151.8,
        display: "US$151.80",
        quantityDiscount: {
          1: {
            discountValue: 62.1,
            discountValueDisplay: "US$62.10",
            unitPrice: 89.7,
            unitPriceDisplay: "US$89.70",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 132,
        display: "USD132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "USD54.00",
            unitPrice: 78,
            unitPriceDisplay: "USD78.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 132,
        display: "$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "$54.00",
            unitPrice: 78,
            unitPriceDisplay: "$78.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 147,
        display: "147,00 €",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "55,50 €",
            unitPrice: 91.5,
            unitPriceDisplay: "91,50 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 147,
        display: "€147.00",
        quantityDiscount: {
          1: {
            discountValue: 55.5,
            discountValueDisplay: "€55.50",
            unitPrice: 91.5,
            unitPriceDisplay: "€91.50",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 132,
        display: "US$132.00",
        quantityDiscount: {
          1: {
            discountValue: 54,
            discountValueDisplay: "US$54.00",
            unitPrice: 78,
            unitPriceDisplay: "US$78.00",
          },
        },
      },
    },
  },
  "pdf-perpetual-ocr": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-perpetual-ocr",
    pricing: {
      PR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PS: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      PW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      QA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AD: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AE: {
        currency: "USD",
        price: 264.6,
        display: "$264.60",
        quantityDiscount: {
          1: {
            discountValue: 31.5,
            discountValueDisplay: "$31.50",
            unitPrice: 233.1,
            unitPriceDisplay: "$233.10",
          },
        },
      },
      AF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      AI: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      AL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AO: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      AQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AR: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      AS: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AT: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      RE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AU: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      AW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      AX: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      AZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      RO: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BA: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BB: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      RS: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      BD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      RU: {
        currency: "USD",
        price: 302.4,
        display: "302,40 $",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 $",
            unitPrice: 266.4,
            unitPriceDisplay: "266,40 $",
          },
        },
      },
      BF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BG: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      RW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BL: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      BM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BO: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      SA: {
        currency: "USD",
        price: 289.8,
        display: "$289.80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "$34.50",
            unitPrice: 255.3,
            unitPriceDisplay: "$255.30",
          },
        },
      },
      BQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SB: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BR: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      SC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SD: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      BT: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      BV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SG: {
        currency: "USD",
        price: 272.16,
        display: "US$272.16",
        quantityDiscount: {
          1: {
            discountValue: 32.4,
            discountValueDisplay: "US$32.40",
            unitPrice: 239.76,
            unitPriceDisplay: "US$239.76",
          },
        },
      },
      BW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SI: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      BY: {
        currency: "USD",
        price: 302.4,
        display: "$302.40",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 266.4,
            unitPriceDisplay: "$266.40",
          },
        },
      },
      SJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      BZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SK: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      SL: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      SM: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      SN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CA: {
        currency: "CAD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      SR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CC: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      SS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ST: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      CF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CH: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      SX: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      SZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CL: {
        currency: "USD",
        price: 299.88,
        display: "US$299,88",
        quantityDiscount: {
          1: {
            discountValue: 35.7,
            discountValueDisplay: "US$35,70",
            unitPrice: 264.18,
            unitPriceDisplay: "US$264,18",
          },
        },
      },
      CM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      CO: {
        currency: "USD",
        price: 299.88,
        display: "US$ 299,88",
        quantityDiscount: {
          1: {
            discountValue: 35.7,
            discountValueDisplay: "US$ 35,70",
            unitPrice: 264.18,
            unitPriceDisplay: "US$ 264,18",
          },
        },
      },
      CR: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      TC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CU: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      CV: {
        currency: "USD",
        price: 252,
        display: "252,00 US$",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "30,00 US$",
            unitPrice: 222,
            unitPriceDisplay: "222,00 US$",
          },
        },
      },
      TG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TH: {
        currency: "USD",
        price: 269.64,
        display: "$269.64",
        quantityDiscount: {
          1: {
            discountValue: 32.1,
            discountValueDisplay: "$32.10",
            unitPrice: 237.54,
            unitPriceDisplay: "$237.54",
          },
        },
      },
      CX: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      CY: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      TJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      CZ: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      TK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      TR: {
        currency: "USD",
        price: 297.36,
        display: "$297,36",
        quantityDiscount: {
          1: {
            discountValue: 35.4,
            discountValueDisplay: "$35,40",
            unitPrice: 261.96,
            unitPriceDisplay: "$261,96",
          },
        },
      },
      TT: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DE: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      TV: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      TW: {
        currency: "USD",
        price: 264.6,
        display: "US$264.60",
        quantityDiscount: {
          1: {
            discountValue: 31.5,
            discountValueDisplay: "US$31.50",
            unitPrice: 233.1,
            unitPriceDisplay: "US$233.10",
          },
        },
      },
      DJ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      TZ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DK: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      DM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      UA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      DZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EC: {
        currency: "USD",
        price: 252,
        display: "$252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30,00",
            unitPrice: 222,
            unitPriceDisplay: "$222,00",
          },
        },
      },
      US: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      EG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      EH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      UY: {
        currency: "USD",
        price: 252,
        display: "US$ 252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$ 30,00",
            unitPrice: 222,
            unitPriceDisplay: "US$ 222,00",
          },
        },
      },
      UZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VA: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      ER: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      VC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      ES: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ET: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VE: {
        currency: "USD",
        price: 252,
        display: "USD252,00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30,00",
            unitPrice: 222,
            unitPriceDisplay: "USD222,00",
          },
        },
      },
      VG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      VI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      VU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FI: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      FJ: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      FO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      FR: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      WF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GB: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      WS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GD: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GG: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      GH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GI: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GL: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      GN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GP: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      GQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GR: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      GS: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      GT: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      GU: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      GY: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      HK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      HM: {
        currency: "AUD",
        price: 321,
        display: "A$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "A$36.00",
            unitPrice: 285,
            unitPriceDisplay: "A$285.00",
          },
        },
      },
      HN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      HR: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      HT: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      YE: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      HU: {
        currency: "EUR",
        price: 271,
        display: "271,00 EUR",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 EUR",
            unitPrice: 235,
            unitPriceDisplay: "235,00 EUR",
          },
        },
      },
      IC: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ID: {
        currency: "USD",
        price: 279.72,
        display: "$279.72",
        quantityDiscount: {
          1: {
            discountValue: 33.3,
            discountValueDisplay: "$33.30",
            unitPrice: 246.42,
            unitPriceDisplay: "$246.42",
          },
        },
      },
      YT: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IE: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IL: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      IM: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      IN: {
        currency: "USD",
        price: 297.36,
        display: "US$ 297.36",
        quantityDiscount: {
          1: {
            discountValue: 35.4,
            discountValueDisplay: "US$ 35.40",
            unitPrice: 261.96,
            unitPriceDisplay: "US$ 261.96",
          },
        },
      },
      IO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      ZA: {
        currency: "USD",
        price: 289.8,
        display: "US$289,80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "US$34,50",
            unitPrice: 255.3,
            unitPriceDisplay: "US$255,30",
          },
        },
      },
      IQ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      IR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      IS: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      IT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      ZM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JE: {
        currency: "GBP",
        price: 249,
        display: "£249.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "£30.00",
            unitPrice: 219,
            unitPriceDisplay: "£219.00",
          },
        },
      },
      ZW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JM: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      JO: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      JP: {
        currency: "USD",
        price: 277.2,
        display: "$277.20",
        quantityDiscount: {
          1: {
            discountValue: 33,
            discountValueDisplay: "$33.00",
            unitPrice: 244.2,
            unitPriceDisplay: "$244.20",
          },
        },
      },
      KE: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KG: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KI: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      KM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KR: {
        currency: "USD",
        price: 277.2,
        display: "US$277.20",
        quantityDiscount: {
          1: {
            discountValue: 33,
            discountValueDisplay: "US$33.00",
            unitPrice: 244.2,
            unitPriceDisplay: "US$244.20",
          },
        },
      },
      KW: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      KY: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      KZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LB: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LC: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LI: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      LK: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      LR: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      LT: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      LU: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      LV: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      LY: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MA: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MC: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MD: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ME: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MF: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MH: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MK: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      ML: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MN: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MO: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MQ: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MR: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MS: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MT: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      MU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MV: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      MW: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      MX: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      MY: {
        currency: "USD",
        price: 267.12,
        display: "US$267.12",
        quantityDiscount: {
          1: {
            discountValue: 31.8,
            discountValueDisplay: "US$31.80",
            unitPrice: 235.32,
            unitPriceDisplay: "US$235.32",
          },
        },
      },
      MZ: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NA: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NC: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NE: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NF: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      NG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NI: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NL: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      NO: {
        currency: "EUR",
        price: 271,
        display: "€ 271,00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€ 36,00",
            unitPrice: 235,
            unitPriceDisplay: "€ 235,00",
          },
        },
      },
      NP: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      NR: {
        currency: "AUD",
        price: 321,
        display: "$321.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "$36.00",
            unitPrice: 285,
            unitPriceDisplay: "$285.00",
          },
        },
      },
      NU: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      NZ: {
        currency: "USD",
        price: 289.8,
        display: "US$289.80",
        quantityDiscount: {
          1: {
            discountValue: 34.5,
            discountValueDisplay: "US$34.50",
            unitPrice: 255.3,
            unitPriceDisplay: "US$255.30",
          },
        },
      },
      OM: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PA: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      PE: {
        currency: "USD",
        price: 252,
        display: "USD252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "USD30.00",
            unitPrice: 222,
            unitPriceDisplay: "USD222.00",
          },
        },
      },
      PF: {
        currency: "USD",
        price: 252,
        display: "$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "$30.00",
            unitPrice: 222,
            unitPriceDisplay: "$222.00",
          },
        },
      },
      PG: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PH: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PK: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
      PL: {
        currency: "EUR",
        price: 271,
        display: "271,00 €",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "36,00 €",
            unitPrice: 235,
            unitPriceDisplay: "235,00 €",
          },
        },
      },
      PM: {
        currency: "EUR",
        price: 271,
        display: "€271.00",
        quantityDiscount: {
          1: {
            discountValue: 36,
            discountValueDisplay: "€36.00",
            unitPrice: 235,
            unitPriceDisplay: "€235.00",
          },
        },
      },
      PN: {
        currency: "USD",
        price: 252,
        display: "US$252.00",
        quantityDiscount: {
          1: {
            discountValue: 30,
            discountValueDisplay: "US$30.00",
            unitPrice: 222,
            unitPriceDisplay: "US$222.00",
          },
        },
      },
    },
  },
  "pdf-monthly-uk": {
    action: "product.price.getall",
    result: "success",
    product: "pdf-monthly-uk",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 8,
        display: "A$8.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 5, display: "£5.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: { currency: "AUD", price: 8, display: "$8.00", quantityDiscount: {} },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
  pdf: {
    action: "product.price.getall",
    result: "success",
    product: "pdf",
    pricing: {
      PR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      QA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AD: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AE: {
        currency: "USD",
        price: 5.25,
        display: "$5.25",
        quantityDiscount: {},
      },
      AF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      AL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AO: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      AQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      AS: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AT: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      RE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AU: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      AW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      AX: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      AZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      RO: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      BA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      RS: {
        currency: "EUR",
        price: 6,
        display: "6,00 EUR",
        quantityDiscount: {},
      },
      BD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      RU: {
        currency: "USD",
        price: 6,
        display: "6,00 $",
        quantityDiscount: {},
      },
      BF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BG: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      RW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BL: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      BM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BO: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      SA: {
        currency: "USD",
        price: 5.75,
        display: "$5.75",
        quantityDiscount: {},
      },
      BQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SB: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BR: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      SC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      BT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SE: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      BV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SG: {
        currency: "USD",
        price: 5.4,
        display: "US$5.40",
        quantityDiscount: {},
      },
      BW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      BY: { currency: "USD", price: 6, display: "$6.00", quantityDiscount: {} },
      SJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      BZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SK: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      SL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      SM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      SN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CA: { currency: "CAD", price: 7, display: "$7.00", quantityDiscount: {} },
      SR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CC: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      SS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ST: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      CF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CH: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      SX: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      SZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CL: {
        currency: "USD",
        price: 5.95,
        display: "US$5,95",
        quantityDiscount: {},
      },
      CM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      CO: {
        currency: "USD",
        price: 5.95,
        display: "US$ 5,95",
        quantityDiscount: {},
      },
      CR: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      TC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      CV: {
        currency: "USD",
        price: 5,
        display: "5,00 US$",
        quantityDiscount: {},
      },
      TG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TH: {
        currency: "USD",
        price: 5.35,
        display: "$5.35",
        quantityDiscount: {},
      },
      CX: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      CY: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      TJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      CZ: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      TR: {
        currency: "USD",
        price: 5.9,
        display: "$5,90",
        quantityDiscount: {},
      },
      TT: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DE: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      TV: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      TW: {
        currency: "USD",
        price: 5.25,
        display: "US$5.25",
        quantityDiscount: {},
      },
      DJ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      TZ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DK: {
        currency: "EUR",
        price: 7,
        display: "7,00 €",
        quantityDiscount: {},
      },
      DM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      UA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      DZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EC: { currency: "USD", price: 5, display: "$5,00", quantityDiscount: {} },
      US: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      EG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      EH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      UY: {
        currency: "USD",
        price: 5,
        display: "US$ 5,00",
        quantityDiscount: {},
      },
      UZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VA: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      ER: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ES: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ET: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VE: {
        currency: "USD",
        price: 5,
        display: "USD5,00",
        quantityDiscount: {},
      },
      VG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      VI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      VU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FI: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      FJ: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      FO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      FR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      WF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GB: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      WS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GD: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GE: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GG: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GI: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GL: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      GN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GP: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      GQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GR: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      GS: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      GT: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      GU: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      GY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      HM: {
        currency: "AUD",
        price: 10,
        display: "A$10.00",
        quantityDiscount: {},
      },
      HN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HR: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      HT: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      YE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      HU: {
        currency: "EUR",
        price: 7,
        display: "7,00 EUR",
        quantityDiscount: {},
      },
      IC: {
        currency: "EUR",
        price: 5,
        display: "5,00 €",
        quantityDiscount: {},
      },
      ID: {
        currency: "USD",
        price: 5.55,
        display: "$5.55",
        quantityDiscount: {},
      },
      YT: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      IE: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IL: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      IM: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      IN: {
        currency: "USD",
        price: 5.9,
        display: "US$ 5.90",
        quantityDiscount: {},
      },
      IO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      ZA: {
        currency: "USD",
        price: 5.75,
        display: "US$5,75",
        quantityDiscount: {},
      },
      IQ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      IS: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      IT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      ZM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JE: { currency: "GBP", price: 7, display: "£7.00", quantityDiscount: {} },
      ZW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JM: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      JO: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      JP: {
        currency: "USD",
        price: 5.5,
        display: "$5.50",
        quantityDiscount: {},
      },
      KE: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KG: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KI: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      KM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KR: {
        currency: "USD",
        price: 5.5,
        display: "US$5.50",
        quantityDiscount: {},
      },
      KW: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      KY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      KZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LB: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LC: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LI: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      LK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      LR: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      LT: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LU: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      LV: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      LY: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MA: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MC: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MD: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ME: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MF: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MH: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MK: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      ML: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MN: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MO: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MQ: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      MR: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MS: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MT: { currency: "EUR", price: 6, display: "€6.00", quantityDiscount: {} },
      MU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MV: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      MW: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MX: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      MY: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      MZ: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NA: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NC: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NE: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NF: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NI: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NL: {
        currency: "EUR",
        price: 6,
        display: "€ 6,00",
        quantityDiscount: {},
      },
      NO: {
        currency: "EUR",
        price: 7,
        display: "€ 7,00",
        quantityDiscount: {},
      },
      NP: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      NR: {
        currency: "AUD",
        price: 10,
        display: "$10.00",
        quantityDiscount: {},
      },
      NU: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      NZ: {
        currency: "USD",
        price: 5.75,
        display: "US$5.75",
        quantityDiscount: {},
      },
      OM: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PA: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PE: {
        currency: "USD",
        price: 5,
        display: "USD5.00",
        quantityDiscount: {},
      },
      PF: { currency: "USD", price: 5, display: "$5.00", quantityDiscount: {} },
      PG: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PH: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PK: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
      PL: {
        currency: "EUR",
        price: 6,
        display: "6,00 €",
        quantityDiscount: {},
      },
      PM: { currency: "EUR", price: 5, display: "€5.00", quantityDiscount: {} },
      PN: {
        currency: "USD",
        price: 5,
        display: "US$5.00",
        quantityDiscount: {},
      },
    },
  },
}
